import React from "react";
import { Container, Row, Col, Image } from 'react-bootstrap';

import "../../aboutus/aboutus.css";

import bannerimage2 from '../../../assets/bannerimage2.svg';
import svgmap from '../../../assets/svgmap.png';

function HeroSection() {
  return (
    <div>
      <Container fluid id="hero" className="herosection">
        <Container fluid className="containersectionabt">
            <Row>
                <Col xs={12} md={12} lg={7}>
                <h2 style={{color:'#FFFFFF',fontFamily:'jost',fontSize:2.5+'rem'}}><strong>About Us</strong></h2>
                <p style={{color:'#FFFFFF', fontSize:0.75+'rem', lineHeight:224+'%', fontFamily:'jost'}} >
                In the fast-paced world of logistics, THE LODA is revolutionizing the way People, Goods, and 
                Services move. Whether you need to  book a ride, transport cargo, or manage a fleet, our app 
                connects you with reliable drivers, riders, and transporters seamlessly. With options ranging 
                from tricycles and cars, to trucks  and delivery services, we ensure your transportation needs 
                are met efficiently and affordably. Say goodbye to the hassle of logistics and embrace a simpler, 
                more effective way to get things moving with THE LODA,
                </p>

                <p style={{color:'#FFFFFF', fontSize:0.75+'rem', lineHeight:224+'%', fontFamily:'jost'}} >
                We are dedicated to providing cutting-edge Logistics ICT Services and Software Technology, offering 
                solutions that empower individuals, businesses, and fleet owners to optimize their operations.</p>
                </Col>

                <Col xs={12} md={12} lg={5}>
                <center>
                  <Image width={100+"%"} src={svgmap}  alt="image" />
                  </center>
                </Col>
             
            </Row>
        </Container>
      </Container>
    </div>
  );
}

export default HeroSection;
