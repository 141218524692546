import React from 'react';
import { Form } from 'react-bootstrap';

const StateDropdown = ({onChange}) => {
  // Replace this array with the actual list of states in Nigeria
  const nigeriaStates = ["Abia", "Adamawa", "Akwa Ibom", "Anambra", "Bauchi", "Borno", "Cross-River", "Delta", "Ebonyi", "Edo", "Ekiti", "Enugu", "Gombe", "Imo", "Jigawa", "Kaduna", "Kano", "Katsina", "Kebbi", "Kogi", "Kwara", "Lagos", "Nasarawa",  "Niger", "Ogun", "Ondo", "Osun", "Oyo", "Plateau", "Rivers", "Sokoto", "Taraba", "Yobe", "Zamfara", "Abuja" ];

  return (
    <Form.Select name="state" onChange={onChange} className="formcontrol">
      <option  style={{fontSize:0.8+"rem",fontFamily:"jost"}}>Select State</option>
      {nigeriaStates.map((state, index) => (
        <option  style={{fontSize:0.85+"rem",fontFamily:"jost",}} key={index} value={state}>
          {state}
        </option>
      ))}
    </Form.Select>
  );
};

export default StateDropdown;
