import React from 'react';
import Question from './Question';
import StickyNavbar from '../reuse-component/Navbar/StickyNavbar';
import Contactus from '../homepage/component/Contactus';
import FooterSection from '../homepage/component/FooterSection';

function Faqs() {
  return (
    <>
       <StickyNavbar />
       <Question />
       <Contactus />
       <FooterSection />
      
    </>
  )
}

export default Faqs
