import React, { useEffect, useState } from "react";
import { Container, Image } from "react-bootstrap";
import StickyNavbar from "../reuse-component/Navbar/StickyNavbar";
import FooterSection from "../homepage/component/FooterSection";
import { useNavigate, useParams } from "react-router-dom";
import Services from '../../services/contactUs'

function BlogPreview() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const response = await Services.listRecord(`blogs/single?slug=${slug}`);
        console.log('data', response.data)
        setResources(response.data);
        setLoading(false);

      } catch (error) {
        console.log('outside error:: ', error);
        setLoading(false);
      }
    };
    fetchResources();
  }, []);

  const formatDate = (date) => {
    const createdAtDate = new Date(date);
    const options = { weekday: "short", day: "numeric", month: "long", year: "numeric" };
    const formattedDate = createdAtDate.toLocaleDateString(
      undefined, options
    );
    return formattedDate;
  }
  const defaultImg = '/assets/yam.png';

  return (
    <>
      <StickyNavbar />
      <Container className="blogpreview">
        <Container>
          <svg onClick={goBack} style={{ transform: 'rotate(-180deg)', cursor: "pointer", marginBottom: 1 + "rem" }} width="42" height="42" viewBox="0 0 153 157" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="153" height="157" rx="76.5" fill="#D2D2D3" />
            <path d="M104.768 80.7678C105.744 79.7915 105.744 78.2085 104.768 77.2322L88.8579 61.3223C87.8816 60.346 86.2986 60.346 85.3223 61.3223C84.346 62.2986 84.346 63.8816 85.3223 64.8579L99.4645 79L85.3223 93.1421C84.346 94.1184 84.346 95.7014 85.3223 96.6777C86.2986 97.654 87.8816 97.654 88.8579 96.6777L104.768 80.7678ZM42 81.5H103V76.5H42V81.5Z" fill="white" />
          </svg>

          <h4 style={{ fontFamily: "jost", color: "#303030" }}>
            <strong>{resources.title}</strong>
          </h4>
          <div style={{ display: 'flex', gap: 10, marginBottom: 1 + "rem", fontFamily: 'jost', fontSize: 0.85 + 'rem' }}>
            <span ><strong>{resources.author}</strong></span>
            <br />
            <span>{formatDate(resources.createdAt)}</span>
          </div>
          <div style={{ height: 400 + 'px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Image style={{ width: '100%', height: 'auto', maxHeight: '100%' }} src={resources.local_img || defaultImg} />
          </div>
          <br />

          <br />
          <div
            style={{
              fontFamily: "jost",
              color: "#303030",
              fontSize: 1.3 + "rem",
              lineHeight: 1.4,
            }}
            dangerouslySetInnerHTML={{ __html: resources.body }}
          >
          </div>
        </Container>
      </Container>
      <FooterSection />
    </>
  );
}

export default BlogPreview;
