import React, { useEffect, useRef} from 'react'
import StickyNavbar from '../reuse-component/Navbar/StickyNavbar'
import HeroSection from './component/HeroSection'
import OpenRole from './component/OpenRole'
import FooterSection from '../homepage/component/FooterSection'
import Contactus from '../homepage/component/Contactus'
import Services from '../../services/contactUs'

function Career() {

  const monitoringInitiated = useRef(false);
  useEffect(() => {
    if (!monitoringInitiated.current) {
      Services.monitor();
      monitoringInitiated.current = true;
    }
  }, []);

  return (
    <>
    <StickyNavbar />
    <HeroSection />
    <OpenRole />
   
   
    <Contactus />
    <FooterSection />
      
    </>
  )
}

export default Career
