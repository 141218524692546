import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";

import otherimage3 from "../../../assets/otherimage3.png";
import otherImage2 from "../../../assets/otherImage2.png";

import "../../aboutus/aboutus.css";
import AnimatedWrapper from '../../homepage/component/AnimatedWrapper';

function OtherSection() {
  return (
    <Container fluid className='otherSection'>
        <Row>
            <Col xs={12} md={7} lg={7} className='otherSectionText' >
              <AnimatedWrapper delay={0.2} >
                <h3 className='otherSectionTextHead' style={{color:'#1D2221'}}><strong>We reimagine the way the <span style={{color:'#F36F2E'}}>goods</span> moves for the better</strong></h3>
                <p style={{fontSize:0.85+'rem',color:'#1D2221',lineHeight:190+'%'}}>
                Movement is what we power. It's our lifeblood. It runs through our veins. It's what gets us out of bed 
                each morning. It pushes us to constantly reimagine how we can move better. For you. For all the places 
                you want to go. For all the things you want to get. For all the ways you want to earn. Across the entire
                world. In real time. At the incredible speed of now. THE LODA is here for you.
                </p>
                </AnimatedWrapper>
            </Col>
            <Col xs={12} md={5} lg={5} >
            <AnimatedWrapper delay={0.4} >
              <div style={{width:100+'%',textAlign:'center',justifyContent:'center',alignItems:'center'}}>
                 <Image width={100+'%'}  src={otherimage3}  alt="image" />
              </div>
             </AnimatedWrapper>
            </Col>
        </Row>
        <Row style={{marginTop:9+"rem"}}>
        <Col xs={12} md={5} lg={5} >
        <AnimatedWrapper delay={0.2} >
              <div style={{width:100+'%',textAlign:'center',justifyContent:'center',alignItems:'center'}}>
                 <Image width={100+'%'} className='otherImage2' src={otherImage2}  alt="image" />
              </div>
              </AnimatedWrapper>
             
            </Col>
            <Col xs={12} md={7} lg={7}  >
            <AnimatedWrapper delay={0.4} >
                <h3 className='otherSectionTextHead' style={{color:'#1D2221'}}><strong> <span style={{color:'#F36F2E'}}>At THE LODA,</span> our commitment is unwavering</strong></h3>
                <p style={{fontSize:0.85+'rem',color:'#1D2221',lineHeight:190+'%'}}>
                We are not just transforming logistics; we are revolutionizing transportation 
                across diverse vehicle types and services. From truck hailing to ride-hailing and tricycle 
                services, we connect businesses and individuals with efficient transportation solutions 
                that meet every need.



                </p>
                <p style={{fontSize:0.85+'rem',color:'#1D2221',lineHeight:190+'%'}}>
                Our truck hailing services span a wide range of specialized vehicles — including sided bodies, 
                open-body trucks, lowbed trucks, flatbeds, container trucks, refrigerator trucks, pickup trucks, 
                trailers, oil tankers, and specialized vehicles like cranes, concrete mixers, and forklifts. Whether 
                you're moving goods or seeking reliable transportation, The Loda has you covered.



                </p>
                <p style={{fontSize:0.85+'rem',color:'#1D2221',lineHeight:190+'%'}} >
                What sets us apart is our commitment to operational efficiency across all services. By optimizing 
                vehicle usage — whether trucks, cars, or tricycles — The Loda eliminates empty return trips, 
                maximizing the potential for increased revenue. Each vehicle, regardless of its size or specialty 
                is utilized to its full capacity. For fleet owners, this means reduced downtime, no more searching for 
                loads, and enhanced profitability in our thriving logistics and transportation network
                </p>
                </AnimatedWrapper>
            </Col>
        </Row>
      
    </Container>
  )
}

export default OtherSection
