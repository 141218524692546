import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import shipper from "../../../assets/shipper.png";

import AnimatedWrapper from "../../homepage/component/AnimatedWrapper";

function BelowSection() {
  return (
    <Container fluid className="belowsection">
      <Row>
        <Col xs={12} sm={12} md={6} lg={6} className="belowsectiontextarea">
        <AnimatedWrapper delay={0.2} >
          <h3 style={{ fontSize: 2.0 + "rem", fontFamily: "jost" }}>
            <strong>Our commitment to safety</strong>
          </h3>
          <p
            style={{
              fontSize: 0.9 + "rem",
              fontFamily: "jost",
              lineHeight: 189 + "%",
            }}
          >
            We understand that your time is valuable, and your peace of mind is essential 
            when The Loda is on the move—whether transporting your cargo or offering rides. 
            That's why we’re dedicated to keeping you connected to the people and places that 
            matter most, while ensuring safety is never compromised.

 
          </p>

          <p
            style={{
              fontSize: 0.9 + "rem",
              fontFamily: "jost",
              lineHeight: 189 + "%",
            }}
          >
           From establishing new safety standards to developing technology that reduces potential risks, 
           The Loda is committed to embedding safety throughout our logistics operations and the platform itself. 
           With features designed to enhance security at every step, safety isn’t just a checkbox for us; it’s an 
           ongoing promise to elevate the standards of logistics and transportation. We’re here to redefine safety, 
           so you can stay focused on what matters, knowing The Loda is taking care of the rest.
 
          </p>
          </ AnimatedWrapper>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} style={{ textAlign: "center" }}>
        <AnimatedWrapper delay={0.4} >
          <Image
          
            width={80 + "%"}
            src={shipper}
            fluid
          />
          </AnimatedWrapper>
        </Col>
      </Row>

      <Row className="safetyexperience">
      <AnimatedWrapper delay={0.2} >
        <div style={{marginBottom:2+'rem'}}>
          <h3 style={{ fontSize: 2.0 + "rem", fontFamily: "jost" }}>
            <strong>How safety is built into your experience</strong>
          </h3>
        </div>
        </AnimatedWrapper>

       
       
        <Col xs={12} sm={12} md={6} lg={6}>
        <AnimatedWrapper delay={0.2} >
          <h5
            style={{
              fontSize: 1.2 + "rem",
              fontFamily: "jost",
              fontWeight: "bold",
            }}
          >
            Dynamic Route Planning for All Services

          </h5>
          <p
            style={{
              fontSize: 0.8 + "rem",
              fontFamily: "jost",
              lineHeight: 189 + "%",
            }}
          >
            At The Loda, safety is a priority across every service, from truck hailing 
            to ride and tricycle hailing. Our dynamic route planning feature leverages 
            real-time data to find the safest and most efficient routes for all vehicles 
            and deliveries. Whether it's a ride, goods delivery, or cargo transport, we ensure 
            safety at every turn, avoiding hazards and optimizing travel times.

          </p>
          </AnimatedWrapper>
        </Col>
       

        <Col xs={12} sm={12} md={6} lg={6}>
        <AnimatedWrapper delay={0.4} >
          <h5
            style={{
              fontSize: 1.2 + "rem",
              fontFamily: "jost",
              fontWeight: "bold",
            }}
          >
            Constant Monitoring and Alerts

          </h5>
          <p
            style={{
              fontSize: 0.9 + "rem",
              fontFamily: "jost",
              lineHeight: 189 + "%",
            }}
          >
            Our advanced system monitors every ride, tricycle, delivery, and shipment. 
            You'll receive real-time alerts for unexpected events, such as route deviations 
            or delays. This proactive monitoring allows for quick action, ensuring your goods 
            and passengers are secure, whether it's a delivery for a business or a personal ride.

          </p>
          </AnimatedWrapper>
        </Col>

        <Col xs={12} sm={12} md={6} lg={6}>
        <AnimatedWrapper delay={0.6} >
          <h5
            style={{
              fontSize: 1.2 + "rem",
              fontFamily: "jost",
              fontWeight: "bold",
            }}
          >
          Real-Time Tracking Across All Services

          </h5>
          <p
            style={{
              fontSize: 0.9 + "rem",
              fontFamily: "jost",
              lineHeight: 189 + "%",
            }}
          >
            With The Loda, you can track everything in real-time. From ride hailing 
            to goods deliveries and large shipments, our platform offers precise updates 
            on the progress of your rides, deliveries, and shipments, as well as estimated 
            arrival times for a seamless experience.

          </p>
          </AnimatedWrapper>
        </Col>

        <Col xs={12} sm={12} md={6} lg={6}>
        <AnimatedWrapper delay={0.8} >
          <h5
            style={{
              fontSize: 1.2 + "rem",
              fontFamily: "jost",
              fontWeight: "bold",
            }}
          >
            Shipment and Ride History:
          </h5>
          <p
            style={{
              fontSize: 0.9 + "rem",
              fontFamily: "jost",
              lineHeight: 189 + "%",
            }}
          >
            Our platform allows users to access a detailed history for all types of 
            services. Whether you’re reviewing past cargo shipments, ride hailing, or 
            deliveries, The Loda offers insights to track performance and identify trends, all of 
            which help to improve safety and efficiency.

          </p>
          </AnimatedWrapper>
        </Col>

        <Col xs={12} sm={12} md={6} lg={6}>
        <AnimatedWrapper delay={0.85} >
          <h5
            style={{
              fontSize: 1.2 + "rem",
              fontFamily: "jost",
              fontWeight: "bold",
            }}
          >
           Comprehensive Insurance Coverage:
          </h5>
          <p
            style={{
              fontSize: 0.8 + "rem",
              fontFamily: "jost",
              lineHeight: 189 + "%",
            }}
          >
            We provide extensive insurance options across all our services. Whether it’s a ride, 
            a tricycle trip, or a large goods shipment, you’re covered with our comprehensive insurance, 
            ensuring protection against any unforeseen incidents during transportation.

          </p>
          </AnimatedWrapper>
        </Col>


        <Col xs={12} sm={12} md={6} lg={6}>
        <AnimatedWrapper delay={0.9} >
          <h5
            style={{
              fontSize: 1.2 + "rem",
              fontFamily: "jost",
              fontWeight: "bold",
            }}
          >
            Rigorous Transporter and Driver Vetting

          </h5>
          <p
            style={{
              fontSize: 0.8 + "rem",
              fontFamily: "jost",
              lineHeight: 189 + "%",
            }}
          >
            We maintain high standards across all our services. The Loda rigorously vets all transporters, 
            drivers, and delivery personnel, ensuring professionalism and adherence to strict safety and 
            quality standards across every service we offer, from passenger rides to large cargo shipments
          </p>
          </AnimatedWrapper>
        </Col>


      </Row>




    
    </Container>
  );
}

export default BelowSection;
