import React from "react";
import "./formfooter.css"; // Import your CSS file for styling
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const FormFooter = () => {
  return (
    <Container fluid >
      <div style={{marginTop:2.1+"rem"}}>
        <h6 style={{color:"#FFFFFF", fontFamily:"jost",fontSize:0.85+"rem"}}>COMPANY</h6>
        <div className="horizontal-list-container">
          <ul className="horizontal-list">
            <Link to="/"> <li>Home</li></Link>
            <Link to="/aboutus"> <li>About Us</li></Link>
            <Link to="/career"> <li>Career</li></Link>
            <Link to="/privacy"> <li>Privacy Policy</li></Link>
            <Link to="/data-deletion"><li>Data Deletion Privacy</li></Link>
          </ul>
        </div>
      </div>

      <div style={{marginTop:2.1+"rem"}}>
        <h6 style={{color:"#FFFFFF", fontFamily:"jost",fontSize:0.85+"rem"}} >HELP</h6>
        <div className="horizontal-list-container">
          <ul className="horizontal-list">
          <Link to="/faqs"> <li>FAQs</li></Link>
            <Link to="/safety"> <li>Safety</li></Link>
            <Link to="/contactus"> <li>Contact Us</li></Link>
          </ul>
        </div>
      </div>
    </Container>
  );
};

export default FormFooter;
