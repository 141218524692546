import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import HomePage from "../src/client/component/homepage/HomePage";
// import ProtectedRoute from "./ProtectedRoute";
// import Notfound from "./component/pagenotfound/Notfound";
// import OurService from "./component/ourservices/OurService";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import ContactUs from "./client/component/contactus/ContactUs";
import AboutUs from "./client/component/aboutus/AboutUs";
import Notfound from "./client/component/pagenotfound/Notfound";
import Safety from "./client/component/safety/Safety";
import Blog from "./client/component/blog/Blog";
import BlogView from "./client/component/blog/BlogView"
import Career from "./client/component/career/Career";
import WhyLoda from "./client/component/WhyLoda/WhyLoda";
import ShipperWaitlist from "./client/component/waitlist/ShipperWaitlist";
import TransporterWaitlist from "./client/component/waitlist/TransporterWaitlist";
import Faqs from "./client/component/FAQs/Faqs";
import PrivacyPage from "./client/component/privacy/PrivacyPage";
import DataDeletion from "./client/component/privacy/DataDeletion";

import LoginPage from "./admin/LoginPage";
import DashboardPage from "./admin/Dashboard/DashboardPage";
import WaitList from "./admin/Dashboard/WaitList";
import Blogs from "./admin/Dashboard/Blogs";
import Contact from "./admin/Dashboard/Contact";
import BlogPreview from "./admin/Dashboard/BlogPreview";
import PrivacyPolicy from "./client/component/privacy/PrivacyPolicy";
import TermsConditionx from "./client/component/privacy/TermsConditionx";
import Visitor from "./admin/Dashboard/Visitor";
import Analytics from "./admin/Dashboard/Analytics";


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


const RootApplication = ({ isAuthenticated }) => {

  return (
    <>
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        <Route path="/data-deletion" exact element={<DataDeletion />} />
        <Route path="/contactus" exact element={<ContactUs />} />
        <Route path="/aboutus" exact element={<AboutUs />} />
        <Route path="/safety" exact element={<Safety />} />
        <Route path="/whyloda" exact element={<WhyLoda />} />
        <Route path="/shipperwaitlist" exact element={<ShipperWaitlist />} />
        <Route path="/transporterwaitlist" exact element={<TransporterWaitlist />} />
        <Route path="/blog" exact element={<Blog />} />
        <Route path="/blog/:slug" exact element={<BlogView /> } />
        <Route path="/faqs" exact element={<Faqs />} />
        <Route path="/career" exact element={<Career />} />
        <Route path="/privacy" exact element={<PrivacyPage />} />
        <Route path="/privacypolicy" exact element={<PrivacyPolicy />} />
        <Route path="/termscondition" exact element={<TermsConditionx />} />
        <Route path="/data-deletion" exact element={<DataDeletion />} />

        <Route path="*" element={<Notfound />} />


        {/* ADMIN ROUTES */}
        <Route path="/secret" element={<LoginPage />} />

        <Route
          path="/ld-admin/dashboard"
          element={isAuthenticated ? <DashboardPage /> : <Navigate to="/secret" replace />}
        />

        {/* <Route
          path="/ld-admin/waitlist"
          element={isAuthenticated ? <WaitList /> : <Navigate to="/secret" replace />}
        /> */}
{/* 
         <Route
          path="/ld-admin/blogs"
          element={isAuthenticated ? <Blogs /> : <Navigate to="/secret" replace />}
        />  */}

        <Route path="/ld-admin/waitlist" element={<WaitList  />} />
        <Route path="/ld-admin/blogs" element={<Blogs />} />

        <Route path="/ld-admin/visitor" element={<Visitor />} />

        <Route path="/ld-admin/blog/:slug" element={<BlogPreview />} />

        <Route path="/ld-admin/analytics" element={<Analytics />}/>

        {/* <Route
          path="/ld-admin/contact"
          element={isAuthenticated ? <Contact /> : <Navigate to="/secret" replace />}
        /> */}

        <Route
          path="/ld-admin/contact"
          element={isAuthenticated ? <Contact /> : <Navigate to="/secret" replace />}
        />
        
      </Routes>
    </>
  );
};

function App() {
  // const [isAuthenticated] = useState(true); 
  const [isAuthenticated, /*setIsAuthenticated*/] = useState(!!localStorage.getItem('token'));
  return (
    <Router>
      <ScrollToTop />
      <RootApplication isAuthenticated={isAuthenticated} />
    </Router>
  );
}

export default App;
