import React, { useState } from "react";
import "./dash.css";
import hamburger from "../assets/hamburger.svg";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Container, Row, Image, Col, Button, Offcanvas, Form, Spinner } from "react-bootstrap";
import DashboardSidebar from "./dashboardSidebar/sidebar";
import News from "./components/News";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import resourceService from "../services/resourses";

const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ['link', 'image'],
    ['clean']
  ],
}

const quillFormats = [
  'header', 'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'link', 'image'
]




const Blogform = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(
    { title: "", body: "", platform: "The Loda", file: null, author: "" }
  );

  const handleOnchange = (event, name) => {
    if (name === 'body') {
      setForm(prevState => ({
        ...prevState,
        body: event
      }));
    } else {
      const { value } = event.target;
      setForm(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };
  
  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setForm(prevState => ({
      ...prevState,
      [name]: files[0]
    }));
  };


  const createBlogPost = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await resourceService.addRecordWithImage('blogs/create', form);
      if (res) {
        if (res.status && res.status === true) {
          toast.success(res.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toast.error(res);
        }
      } else {

        toast.error("There was an error processing this request");
      }
    } catch (error) {

      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };


  return (
    
    <Form  >
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="formlabel">Title</Form.Label>
        <Form.Control
          required
          onChange={(value) => handleOnchange(value, 'title')}
          className="formcontrol"
          type="text"
          name="title"
          placeholder="Enter blog title"
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="formlabel">Body</Form.Label>
        <ReactQuill
          placeholder="Enter blog content"
          className="formcontrol"
          name="body"
          value={form.body}
          onChange={(value) => handleOnchange(value, 'body')}
          modules={quillModules}
          formats={quillFormats}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="formlabel">Author</Form.Label>
        <Form.Control
          required
          onChange={(value) => handleOnchange(value, 'author')}
          className="formcontrol"
          type="text"
          name="author"
          placeholder="Enter blog author"
        />
      </Form.Group>

      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label className="formlabel" >Banner Image</Form.Label>
        <Form.Control 
        className="formcontrol" 
        type="file" 
        name="file"
        onChange={handleFileChange} 
        />
      </Form.Group><br />

      <br />

      <Button
        onClick={createBlogPost}
        variant="primary"
        style={{
          backgroundColor: "#F36F2E",
          borderColor: "#313434",
          color: "#FFFFF",
          borderRadius: 36 + "px",
          textAlign: "center",
        }}
        size="md"
        disabled={isLoading}
      >
        {isLoading ? (
          <>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.3 + "rem",
                marginRight: 0.3 + "rem",
              }}
            >
              Loading...
            </span>
          </>
        ) : (
          <span
            style={{
              fontSize: 0.8 + "rem",
              marginLeft: 0.3 + "rem",
              marginRight: 0.3 + "rem",
            }}
          >
            CREATE BLOG
          </span>
        )}
      </Button>
    </Form>
  );
};



const Blogs = () => {
  const [toggled, setToggled] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Container
      fluid
      style={{ display: "flex", width: 100 + "%" }}
      className="dashboardContainer"
    >
      <DashboardSidebar
        onBackdropClick={() => setToggled(false)}
        toggled={toggled}
      />
      <ToastContainer />

      <Container fluid className="bodycontainer">
        <div
          style={{
            display: "flex",
            gap: 1 + "rem",
            alignContent: "center",
            alignItems: "center",
          }}
          onClick={() => setToggled(!toggled)}
        >
          <Image src={hamburger} />
          <h6>Blog</h6>
        </div>
        <br />

        <Container fluid>
          <Row
            style={{

              backgroundColor: "white",
              display: "flex",
              padding: 1 + "rem",
              borderRadius: 0.5 + "rem",
              marginBottom: 1 + "rem",

            }}>
            <Col sm={12} md={8} lg={8}>
              <h4 style={{ fontFamily: "jost", fontSize: 1.2 + "rem" }}>
                <strong>Create a blog post here</strong>
              </h4>
              <p style={{ fontFamily: "jost", fontSize: 1 + "rem" }}>
                Welcome to the blog post area.<br/>
                From here, you can create a blog post, toggle it online or offline, update and delete posts.
              </p>
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: 0,
                paddingRight: 0,
              }}
              sm={12}
              md={4}
              lg={4}
            >
              <center>
                <Button
                  onClick={handleShow}
                  variant="primary"
                  style={{
                    backgroundColor: "#F36F2E",
                    borderColor: "#313434",
                    color: "#FFFFF",
                    borderRadius: 36 + "px",
                    textAlign: "center",
                    width: "100%",
                  }}
                  size="lg"
                  className="w-100"
                >
                  <span
                    style={{
                      fontSize: 0.9 + "rem",
                      marginLeft: 1.5 + "rem",
                      marginRight: 1.5 + "rem",
                    }}
                  >
                    Create Blog Post
                  </span>
                </Button>{" "}
              </center>
            </Col>
          </Row>
        </Container>

        <Container fluid style={{ backgroundColor: "white", padding: 0.9 + "rem", borderRadius: 0.5 + "rem", marginBottom: 3 + "rem" }}>
          <News handleShow={handleShow} handleClose={handleClose} />
        </Container>

        <Offcanvas show={show} className="offcanvas" placement='end' backdrop="static" onHide={handleClose} >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title style={{ fontFamily: "jost", fontSize: 1.2 + "rem" }}><strong>Create Blog Post</strong></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Blogform />
          </Offcanvas.Body>
        </Offcanvas>

      </Container>
    </Container>
  );
};

export default Blogs;




