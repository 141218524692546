import React, { useEffect, useState } from "react";
import { Container, Image, Table, Row, Col } from "react-bootstrap";
import "./dash.css";

import { useNavigate, useParams } from "react-router-dom";
import resourceService from "../services/resourses";

function Analytics() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const response = await resourceService.listRecord(`analytics/visitors`);
        console.log('data', response.data)
        setResources(response.data.data);
        setLoading(false);

      } catch (error) {
        console.log('outside error:: ', error);
        setLoading(false);
      }
    };
    fetchResources();
  }, []);


  const formatDate = (date) => {
    const createdAtDate = new Date(date);
    const options = { weekday: "short", day: "numeric", month: "long", year: "numeric" };
    const formattedDate = createdAtDate.toLocaleDateString(
      undefined, options
    );
    return formattedDate;
  }


  const renderResourceRows = () =>
    resources.map(( resource, index) => {
      const formattedPageVisited = Array.isArray(resource.page_visited) ? resource.page_visited.join(', ') : resource.page_visited;

      // const duration = new Date(resource.createdAt).getTime() - new Date(resource.updatedAt).getTime();
      return (
        <tr key={index}>
        <td>{index + 1}</td>
        <td>{resource.original_ip}</td>
        <td>{resource.machine}</td>
        <td>{resource.org}</td>
        <td>{resource.country}</td>
        <td>{resource.city}</td>
        <td>{resource.region}</td>
        <td>{resource.timezone}</td>
        <td>{formatDate(resource.createdAt)}</td>
        <td>{formattedPageVisited}</td>
        {/* <td>{(Math.abs(duration) / 1000)/60} s</td> */}
      </tr>
      );
    })

  return (
    <Container className="blogprevie">
      <Container>
        <svg onClick={goBack} style={{ transform: 'rotate(-180deg)', cursor: "pointer", marginBottom: 1 + "rem" }} width="42" height="42" viewBox="0 0 153 157" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="153" height="157" rx="76.5" fill="#D2D2D3" />
          <path d="M104.768 80.7678C105.744 79.7915 105.744 78.2085 104.768 77.2322L88.8579 61.3223C87.8816 60.346 86.2986 60.346 85.3223 61.3223C84.346 62.2986 84.346 63.8816 85.3223 64.8579L99.4645 79L85.3223 93.1421C84.346 94.1184 84.346 95.7014 85.3223 96.6777C86.2986 97.654 87.8816 97.654 88.8579 96.6777L104.768 80.7678ZM42 81.5H103V76.5H42V81.5Z" fill="white" />
        </svg>

        <h4 style={{ fontFamily: "jost", color: "#303030" }}>
          <strong>
            Analytics Page
          </strong>
        </h4>

        <Row>
          <Table striped responsive>
            <thead style={{ fontSize: 1 + "rem", fontFamily: "jost" }}>
              <tr>
                <th>#ID</th>
                <th>IP</th>
                <th>Device</th>
                <th>Network</th>
                <th>Country</th>
                <th>City</th>
                <th>Region</th>
                <th>Timezone</th>
                <th>Date</th>
                <th>Pages Visited</th>
                {/* <th>Duration</th> */}
                
              </tr>
            </thead>
            <tbody style={{ fontSize: 0.90 + "rem", fontFamily: "jost" }}>
              {renderResourceRows()}
            </tbody>
          </Table>

        </Row>
      </Container>
    </Container>
  );
}

export default Analytics;
