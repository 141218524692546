import React, {useEffect, useRef} from "react";
import StickyNavbar from '../reuse-component/Navbar/StickyNavbar';
import Privacy from './component/Privacy'
import FooterSection from "../homepage/component/FooterSection";
import Services from '../../services/contactUs'

function PrivacyPolicy() {

	const monitoringInitiated = useRef(false);
  useEffect(() => {
    if (!monitoringInitiated.current) {
      Services.monitor();
      monitoringInitiated.current = true;
    }
  }, []);
	
	return (
		<>
			<StickyNavbar />
			<Privacy />
			<FooterSection />
		</>
	);
}

export default PrivacyPolicy;
