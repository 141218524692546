import React from 'react'
import { Container, Row } from 'react-bootstrap';

import "../../safety/safety.css";

function Herosection() {
  return (
    <Container fluid id="hero" className="SafetyHero">
      <Row className="SafetyHeader">
        <h2 style={{fontFamily:'jost', fontSize:2.5+'rem',fontWeight:'bold'}}>
          <strrong>Your Safety, Our Priority  </strrong>
        </h2>

        <p style={{fontSize:0.85+'rem', lineHeight: 189 + "%"}}>
        At The Loda, safety remains at the core of every journey—whether it's providing 
        rides, moving goods or delivering services. We understand that transportation is 
        not just about moving from point A to point B; it’s about trust, reliability, and peace 
        of mind for both customers and transport partners.


        </p>

        <p style={{fontSize:0.85+'rem',marginTop:1.5+'rem', lineHeight: 189 + "%"}}>
        That's why The Loda has implemented a comprehensive safety framework that spans all aspects 
        of our services. From rigorous vetting processes for drivers and transporters to real-time 
        tracking for transparency, our platform incorporates advanced technology to proactively prevent 
        and mitigate potential risks. Every trip is monitored, ensuring the highest level of safety and 
        security for goods, passengers, and transporters alike.

        </p>

        <p style={{fontSize:0.85+'rem',marginTop:1.5+'rem', lineHeight: 189 + "%"}}>
        Our commitment to safety is further strengthened through continuous improvement of our protocols, 
        ensuring that whether you're a passenger, a transporter, or a business relying on deliveries, you 
        can trust The Loda to deliver a secure and reliable experience. Safety is more than just a feature—it’s 
        the foundation of every service we offer.
        </p>
      </Row>
       
      
    </Container>
  )
}

export default Herosection
