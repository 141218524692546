import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";

import "../../career/career.css";

function HeroSection() {
  return (
    <div>
      <Container fluid id="hero" className="herosection">
        <Container fluid className="containersectionabt">
          <Row>
            <Col xs={12} md={8} lg={6}>
              <h2
                style={{
                  color: "#FFFFFF",
                  fontFamily: "jost",
                  fontSize: 2.5 + "rem",
                }}
              >
                <strong>Careers</strong>
              </h2>
              <p
                style={{
                  color: "#FFFFFF",
                  fontSize: 0.75 + "rem",
                  lineHeight: 224 + "%",
                  fontFamily: "jost",
                }}
              >
                Lorem ipsum dolor sit amet consectetur. Tellus pulvinar cras sed
                posuere duis. Velit euismod quis sed ut quis. Elit sit facilisis
                viverra facilisi adipiscing.{" "}
              </p>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  className="careerform"
                  style={{backgroundColor:'#424242',borderColor:'#424242',fontSize:0.9+'rem',color:'#FFFFFF'}}
                  type="text"
                  placeholder="Search for Opening Position"
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4} lg={6}></Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export default HeroSection;
