import React from 'react';
import { Link } from 'react-router-dom'
import { Container, Accordion } from 'react-bootstrap';
import { easeOut, motion } from "framer-motion";
import "../../homepage/homepage.css";

const QuestionData = [
  {
    id: "1",
    num:"0.2",
    Question: "What services does The Loda offer?",
    Answer: "The Loda provides a variety of transportation solutions, including car ride-hailing, tricycle hailing, truck hailing for goods transportation, and delivery services for individuals, restaurants, supermarkets, and pharmacies. Whether you need a quick ride or heavy-duty transportation, The Loda has you covered.",
  },
  {
    id: "2",
    num:"0.4",
    Question: " How do I book a ride or transport goods using The Loda? ",
    Answer: "Booking with The Loda is simple! Just download the app, sign up, and choose the service you need — whether it’s a ride, tricycle, or truck. Enter your destination or delivery details, and our network of drivers will be at your service.",
  },
  {
    id: "3",
    num:"0.6",
    Question: " How can I track my ride or delivery?",
    Answer: "Once your ride or shipment is confirmed, you can track it in real-time through our app. You’ll see the driver's location and receive updates on the estimated time of arrival.",
  },
  {
    id: "4",
    num:"0.8",
    Question: "What areas does The Loda currently operate in?",
    Answer: "The Loda is currently set to be available in cities across Nigeria. As we expand, we will also offer services in other African countries, including Kenya, Ghana, South Africa, Morocco, Egypt, and Uganda.",
  },
  {
    id: "5",
    num:"0.9",
    Question: "How are prices determined for rides and goods transportation?",
    Answer: "Prices are determined based on factors such as distance, type of service (e.g., car, tricycle, or truck), and demand. You’ll always see the estimated cost upfront before confirming your booking.",
  },
  {
    id: "6",
    num:"0.95",
    Question: "What payment methods does The Loda accept?",
    Answer: "We accept multiple payment methods, including Cash, credit/debit cards, wallets, Bank Transfers, and other secure digital payment platforms. You can also load your Loda wallet to make payments faster.",
  },
 
];



function Question() {
  return (
    <Container fluid className="FAQ" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center" }}>
      
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: -5, opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.4, timingFunction: easeOut }}
        viewport={{ once: true }}
      >
        
  
      <h2 style={{fontFamily:'jost',fontSize:2.2+'rem',color:'#515151',textAlign:'center'}} ><strong>Frequently Asked Questions</strong></h2>
      <p style={{textAlign:'center', fontFamily:'jost',fontSize:0.85+'rem'}}>Below are list of frequently asked questions, tap on a question to view the answer.</p>
      </motion.div>
      <br />

      <Accordion className="mainaccordion" defaultActiveKey="0" flush>
        {QuestionData.map((item) => (
           <motion.div
           initial={{ y: 20, opacity: 0 }}
           whileInView={{ y: -5, opacity: 1 }}
           transition={{ duration: 0.3, delay: item.num, timingFunction: easeOut }}
           viewport={{ once: true }}
         >
          <Accordion.Item key={item.id} eventKey={item.id}>
            <Accordion.Header>{item.Question}</Accordion.Header>
            <Accordion.Body style={{ justifyContent: 'left' }}>
              {item.Answer}
            </Accordion.Body>
          </Accordion.Item>
          </motion.div>
        ))}
      </Accordion>
      
      <br/>
      <Link to='/faqs'>
      <span style={{fontFamily:"jost",fontSize:0.86+"rem",color:"#6E6E6E"}} >See All FAQ's</span>
      </Link>
      

    </Container>
  )
}

export default Question;
