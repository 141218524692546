import React, {useEffect, useRef } from "react";
import StickyNavbar from "../reuse-component/Navbar/StickyNavbar";


import FooterSection from "../homepage/component/FooterSection";
import News from "../homepage/component/News";
import Services from '../../services/contactUs'

function Blog() {

  const monitoringInitiated = useRef(false);
  useEffect(() => {
    if (!monitoringInitiated.current) {
      Services.monitor();
      monitoringInitiated.current = true;
    }
  }, []);

  return (
    <>
      <StickyNavbar />
      <News />
      <FooterSection />
    </>
  );
}

export default Blog;
