import React from "react";
import { Container, Row, Col } from 'react-bootstrap';

import "../PrivacyPolicy.css";

function TermsCondition() {
	return (
		<div>

			<Container fluid id="hero" className="herosection">
				<Container fluid className="containersectionabt">
					<Row>
						<Col xs={12} md={8} lg={6}>
							<h2 style={{ color: '#FFFFFF', fontFamily: 'jost', fontSize: 2.5 + 'rem' }}><strong>Terms and Conditions of Services</strong></h2>
							<p style={{ color: '#FFFFFF', fontSize: 0.75 + 'rem', lineHeight: 224 + '%', fontFamily: 'jost' }} >
								Please carefully review our terms and condition provided below. </p>
						</Col>
						<Col xs={12} md={4} lg={6}></Col>
					</Row>
				</Container>
			</Container>

			<Container fluid id="hero" className="headings" style={{ marginTop: -4 + "rem",fontFamily:"jost" }}>
				<Container>
				
					<p className="privacy-content">
                    “The website located at www.theloda.co (the “Site”) and the associated mobile application (“Mobile App”) are copyrighted works belonging to The Loda Logistics Technologies Ltd. (“The Loda”, “us”, and “we”). The Loda Logistics Technologies Ltd. provides a service that connects motor carrier businesses (“Transporters”) to entities who need to ship cargo (“Shippers”) (collectively, with the Site, Mobile App and all services provided therein or otherwise provided by The Loda Logistics Technologies Ltd, the “Service”). The Service may be subject to additional guidelines, terms, or rules, including, but not limited to, The Loda Logistics Technologies Ltd Shipper Accessorial Rates and The Loda Logistics Technologies Ltd Transporter Accessorial Rates provided on the Service (collectively, the “Accessorial Rates”), any Standard Operating Procedure documents (“SOP”s) and/or Work Orders put into place between The Loda Logistics Technologies Ltd and Shippers or Transporters, and The Loda Logistics Technologies Ltd Privacy Policy. All such additional guidelines, terms or rules are incorporated by reference into this Agreement. 
					</p>
					<p className="privacy-content">
                    THESE TERMS OF SERVICE (“AGREEMENT”) SET FORTH THE LEGALLY BINDING TERMS FOR YOUR USE OF THE SERVICE. BY ACCESSING OR USING THE SERVICE, YOU ARE ACCEPTING THIS AGREEMENT, ON BEHALF OF YOURSELF OR THE COMPANY, ENTITY OR ORGANIZATION THAT YOU REPRESENT, AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THIS AGREEMENT, ON BEHALF OF YOURSELF OR THE COMPANY, ENTITY OR ORGANIZATION THAT YOU REPRESENT. YOU MAY NOT ACCESS OR USE THE SERVICE OR ACCEPT THIS AGREEMENT IF YOU ARE NOT AT LEAST 18 YEARS OLD. IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS OF THIS AGREEMENT, DO NOT ACCESS OR USE THE SERVICE. THIS AGREEMENT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS, AND ALSO LIMITS THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE. AGREEMENT TO E-SIGNATURES. WHEN YOU REGISTER AN ACCOUNT ON THE The Loda SERVICE OR CLICK OR TAP ON THE “GO ONLINE”, “ACCEPT REQUEST”, OR “TAP TO START” BUTTONS WHEN ACCEPTING OR FULFILLING A SHIPMENT, YOU AGREE THAT YOU ARE INTENDING TO CREATE AN ELECTRONIC SIGNATURE AFFIRMING YOUR FULL UNDERSTANDING AND AGREEMENT TO BE LEGALLY BOUND BY ALL OF THESE TERMS OF SERVICE AND THAT YOU ARE LEGALLY PERMITTED TO USE AND ACCESS THE the loda SERVICE. WE ADVISE THAT YOU PRINT AND RETAIN A COPY OF THESE TERMS OF SERVICE BEFORE YOU CLICK OR TAP ON THE “GO ONLINE” OR ”ACCEPT SHIPMENT” OR “TAP TO START” BUTTONS. THE MOST CURRENT VERSION OF THESE TERMS OF SERVICE WILL ALWAYS BE AVAILABLE FOR YOUR REVIEW UNDER THE TERMS OF SERVICE LINK LOCATED AT WWW.THELODA.CO.
					</p>

					<p className="privacy-content">
                    Payment processing services for drivers on The Loda are provided by Wema Bank and are subject to the Wema Bank Connected Account Agreement, which includes the Wema Bank Terms of Service (collectively, the “Wema Services Agreement”). By agreeing to these terms or continuing to operate as a Transporter or driver on The Loda, you agree to be bound by the Wema Bank Services Agreement, as the same may be modified by Wema Bank from time to time. As a condition of The Loda enabling payment processing services through Wema Bank, you agree to provide The Loda accurate and complete information about you and your business, and you authorize The Loda to share it and transaction information related to your use of the payment processing services provided by Wema Bank.
					</p>


                    <h3 style={{ marginTop: 2.5 + "rem" }}><strong>1. SERVICE DESCRIPTION</strong></h3>

					
					<h5 style={{ marginTop: 2.5 + "rem" }}>
						<strong>1.1. General.</strong>
						
					</h5>
					<p className="privacy-content">
                    The Loda Logistics Technologies Ltd helps to connect Shippers and Transporters for the shipment of freight and cargo by providing a platform whereby Shippers can post details of desired shipment requests and Transporters can accept such shipment requests. “User” means any user of the Service, and may be a Transporter or a Shipper. To the extent you are a Transporter, the provisions in this Agreement regarding Transporters apply to you. To the extent you are a Shipper, the provisions in this Agreement regarding Shippers apply to you. To the extent you are a User, the provisions in this Agreement regarding Users apply to you. 
					</p>
					<p className="privacy-content">
                     For the avoidance of doubt, The Loda Logistics Technologies Ltd. provides the platform for posting and accepting Shipments and the billing service described in Section 5. Please carefully read the disclaimer in Section 11 for more information about your responsibilities.
					</p>




                    <h5 style={{ marginTop: 2.5 + "rem" }}>
						<strong>1.2. Shipment Requests.</strong>
					</h5>
					<p className="privacy-content">
                    Shipper may post a request for a shipment (“Shipment”) through the Service. Without limitation, Shipper will provide origin and destination addresses, the requested date and pick up time of the Shipment, a description of the items being shipped (including weight) and any other relevant information about the Shipment. The Loda Logistics Technologies Ltd. is not responsible for any Shipment terms. Once the Shipment is accepted for posting by The Loda Logistics Technologies Ltd., the details of the Shipment are posted to the Service. After a certain period of time, the Shipment will be viewable to all Transporters in the immediate area, though The Loda Logistics Technologies Ltd. may from time to time, and in order to meet Shippers’ specific needs, release shipments to certain Transporters before posting it to the Service. 
					</p>
					<p className="privacy-content">
                    Shippers may cancel a Shipment at any time prior to it being accepted by a Transporter. Transporters may view available Shipments in their immediate area at any time and accept a Shipment through the Service. The first Transporter to accept a Shipment will be assigned the Shipment. Once accepted by a Transporter, The Loda Logistics Technologies Ltd. will notify Shipper that the Shipment has been accepted. Shipper and Transporter will receive each other’s phone number. Each Shipper and Transporter is responsible for providing their own phones for use with the Service. 
					</p>

                    <p className="privacy-content">
                    The Loda Logistics Technologies Ltd. may also, from time to time as the circumstances require, connect Shippers and Transporters outside of the Mobile App. The Loda Logistics Technologies Ltd. does not guarantee that a Shipment will be accepted by a Transporter. If a Shipment is not accepted by a Transporter, The Loda Logistics Technologies Ltd. will notify the Shipper that no Transporter is available and that the Shipper should try posting the Shipment again.
					</p>




                    <h5 style={{ marginTop: 2.5 + "rem" }}>
						<strong>1.3. Completing the Shipment.</strong>
						
					</h5>
					<p className="privacy-content">
                    Shipper must confirm on the mobile app that the shipment was delivered by the Transporter. Once the Shipment has been completed, Transporter will promptly collect the authorized recipient's signature in the Mobile App through tappinng the “confirm offload buttton” by the shipper.
					</p>
					
                  
    


                    <h3 style={{ marginTop: 2.5 + "rem" }}><strong>2. TRANSPORTER TERMS, REPRESENTATIONS AND WARRANTIES</strong></h3>

					
                    <h5 style={{ marginTop: 2.5 + "rem" }}>
						<strong>2.1. Transporter Minimal Requirements.</strong>
						
					</h5>
					<p className="privacy-content">
                    As a Transporter, you hereby represent, warrant, and covenant that you have provided The Loda Logistics Technologies Ltd.: <br/>(a)Your Company Registration Certificate <br/>(b) Director’s Government issued ID Card <br/>(c)Truck License Plate & Chasis Number <br/>(d) Vehicle Proof of ownership certificate, Certificate of Road worthiness and General Motor Receipt  <br/>(e) prior to moving your first Shipment with The Loda Logistics Technologies Ltd., certificate(s) of comprehensive and Goods in transit insurance policy. 
					</p>
					

                    
                    <h5 style={{ marginTop: 2.5 + "rem" }}>
						<strong>2.2. Insurance.</strong>
						
					</h5>
					<p className="privacy-content">
                    You hereby further represent, warrant, and covenant that: <br/>(a) You carry General Liability insurance. <br/>(b) You carry workers compensation insurance or equivalent as required by applicable law, and if you do not carry workers compensation insurance, it is because you are specifically exempted from a legal requirement to carry it. <br/>(c) You have procured insurance from companies maintaining a rating of B+ or higher. <br/>(d) You will list The Loda Logistics Technologies Ltd. or its designated representative as a certificate holder on your Auto Liability and Cargo Liability insurance policies and, upon request, provide copies of all policies and endorsements. <br/>(e) You will ensure that The Loda Logistics Technologies Ltd. is provided notice of cancellation or modification of any insurance required under this agreement at least 30 days in advance of any cancellation or modification of the required insurance. Without limitation, you agree to indemnify and defend The Loda Logistics Technologies Ltd. for any failure to maintain the above mentioned insurance or to properly notify The Loda Logistics Technologies Ltd. of such failure. <br/>(f) Your Automobile Liability insurance covers all automobiles you will use to perform services under this Agreement. <br/>(g)  Your insurance covers contractual liability assumed under these Terms of Service. <br/>(i) You will not accept shipments for commodities or perform any services which are excluded by your insurance policies.
					</p>


                    <h5 style={{ marginTop: 2.5 + "rem" }}>
						<strong>2.3. Compliance with Laws and Regulations.</strong>
						
					</h5>
					<p className="privacy-content">
                    You hereby represent, warrant, and covenant that: <br/>(a) You are duly authorized to provide shipping services as a contract transporter of commodities and desire to provide shipping services for Shipments. <br/>(b) You currently, and shall during all periods you transport shipments through the Service, have all applicable licenses, permits, registrations, approvals, and authority under state, local and federal law to provide the shipping services subject to this Agreement, and such authority shall cover the commodities, geographical scope, and special Shipper instructions or requirements related to all transportation services you provide. <br/>(c) You will comply with all applicable local, state and federal laws related to the provision of shipping services, including without limitation those of the Nigerian Ministry of Transportation. 
					</p>




                    <h5 style={{ marginTop: 2.5 + "rem" }}>
						<strong>2.4. Safety Ratings and Compliance.</strong>
						
					</h5>
					<p className="privacy-content">
                    (a) You agree to maintain a satisfactory safety rating as determined by any state or provincial authority with jurisdiction over your operations.<br/>
                     (b) You will immediately notify The Loda Logistics Technologies Ltd if your safety rating becomes Unsatisfactory or Conditional, or if you receive a negative safety evaluation from a state or provincial authority with jurisdiction over your operations.
					</p>


                    
                    <h5 style={{ marginTop: 2.5 + "rem" }}>
						<strong>2.5. Equipment.</strong>
						
					</h5>
					<p className="privacy-content">
                    Regarding the equipment utilized for shipping services under these Terms of Service, you affirm the following: <br/>(a) You will furnish, at your own expense, all necessary equipment for performing shipping services and cover all related operational and maintenance costs, including fines, penalties, or fees. <br/>(b) The device that the truck driver uses for shipment trip update must be switched on through out the delivery duration of any shipment accepted by the transporter/driver until the shipment is deliverd.<br/>(c)The equipment provided will adhere to all relevant governmental regulations, standards, testing, licensing, permits, and registration, ensuring quality and quantity sufficient to meet the transportation requirements of each shipment. <br/>(d) Any motor vehicle equipment used for transporting food grade products will comply with Sanitary Food Transportation Standards.
					</p>


                    <h5 style={{ marginTop: 2.5 + "rem" }}>
						<strong>2.6. Employees And Services.</strong>
						
					</h5>
					<p className="privacy-content">
                    Regarding your employees engaged in shipping services under these Terms of Service, you declare the following: <br/> (a) You will employ and assume sole responsibility for all personnel involved in providing shipping services. <br/>(b) You will ensure payment of wages and applicable insurance for your personnel, including workers’ compensation insurance. <br/>(c) Compliance with regulations governing employees' working hours under applicable law will be ensured. <br/> (d) You bear sole responsibility for your drivers' actions and compliance with all relevant laws and regulations. <br/> (e) You are solely responsible for hiring, training, disciplining, and terminating your drivers and other employees. <br/>(f) Only licensed drivers and operating personnel will be utilized for the services they are engaged in. <br/>(g) Shipments from the Service will not be brokered out to another carrier. <br/> (h) Documented driver validation procedures, recent driver logs and visit records, and proof of identification checks will be maintained. <br/>(i) Drivers will conduct documented physical inspections upon cargo receipt and release, note any discrepancies before sign-off, maintain OS&D procedures, and implement written container security procedures. 
					</p>


                    <h5 style={{ marginTop: 2.5 + "rem" }}>
						<strong>2.7. Shipper Instructions.</strong>
						
					</h5>
					<p className="privacy-content">
                    If the Shipper provides specific instructions regarding freight loading, handling, and shipping, those instructions shall prevail, and you shall be obligated to adhere to them.
					</p>


                    <h5 style={{ marginTop: 2.5 + "rem" }}>
						<strong>2.8. Payment and Remedies</strong>
						
					</h5>
					<p className="privacy-content">
                    (a) You agree to seek payment solely from The Loda Logistics Technologies Ltd for any freight and other charges owed under this Agreement and acknowledge that your sole recourse in the event of nonpayment shall be against The Loda Logistics Technologies Ltd, and not Shipper, Consignee, or any of their customers. You waive any claims against The Loda Logistics Technologies Ltd's customers for payment of charges for services rendered hereunder. This undertaking shall survive the termination of this Agreement.<br/>
                    (b) You shall not claim, and hereby waive any right to claim, any lien on any Shipment.
					</p>



                    <h5 style={{ marginTop: 2.5 + "rem" }}>
						<strong>2.9. Non-solicit</strong>
						
					</h5>
					<p className="privacy-content">
                    You shall not solicit, accept, or book shipments with any Shipper, Origin Location, Consignee, or other Carrier, nor pursue any business you first became aware of due to The Loda Logistics Technologies Ltd, for 12 months after the termination of your account with The Loda Logistics Technologies Ltd. The Loda Logistics Technologies Ltd reserves the right to terminate your Account or this Agreement in its sole discretion for a breach or suspected breach of this Section.
					</p>



                    
                    <h5 style={{ marginTop: 2.5 + "rem" }}>
						<strong>2.10. Acceptance of Liability</strong>
						
					</h5>
					<p className="privacy-content">
                    (a) You are solely responsible for any and all liability resulting from the shipping services you provide under this Agreement, including property damage, cargo loss, damages, delay, personal injury, and death. Your liability in all cases shall be as a Transporter. Your liability is for the full amount of any such loss, damage, cost, or other liabilities, irrespective of your insurance limits.<br/>
                    (b) You agree to defend, indemnify, hold harmless and/or make whole The Loda Logistics Technologies Ltd, Shippers, co-brokers, and consignees (and their officers, employees, and agents), including costs and attorney’s fees, with respect to any and all claims, demands, loss, damage, expenses, or liability, made by any party due to or arising out of your or your agents’: <br/>(i) use of the Service; <br/> (ii) User Content; <br/>(iii) interaction with any other User; <br/>(iv) violation of any of the terms of this Agreement; <br/>(v) driver salaries, wage, overtime, and meal/rest period claims, local, state, and federal payroll and other withholding taxes, unemployment insurance, pensions, workers’ compensation, social security, and related protections, and any expenses related to equipment and fuel; <br/>(vi) violation of applicable laws or regulations; or <br/>(vii) performance of shipping services pursuant to this Agreement. This indemnification applies regardless of any provisions in separate contracts between The Loda Logistics Technologies Ltd and third parties. You also hereby waive any claims or demands by you against The Loda Logistics Technologies Ltd related to any costs, losses, expenses, or liability arising from the above-listed actions by you or your agents.
					</p>


                    <p className="privacy-content">(c) Without limiting any of the foregoing provisions, you specifically agree to defend, indemnify, hold harmless and/or make whole The Loda Logistics Technologies Ltd, Shippers, and consignees (and their officers, employees, and agents), including costs and attorney’s fees, with respect to any and all claims, demands, loss, damage, expenses, or liability, made by any party or incurred by you due to or arising out of your, or your agents’:
                        i. Failure to use commercially reasonable efforts to pick up accepted Shipments at the designated point of origin within as soon as practicable, or use commercially reasonable efforts to proceed to the point of destination specified or perform timely, efficient and reliable pick up and delivery of all shipments. <br/>
                        ii. Failure to obtain from the Shipper either an electronic signature in the Mobile App or hard copy Waybill or  Bill of Lading (containing, at minimum, the names and addresses of you and the Shipper, the origin and destination addresses, the requested pick up and delivery dates and times, description of the items being shipped (including weight), and any special shipping instructions or freight protection requirements), or your failure to confirm that the cargo matches the description and quantities of the cargo in the shipment request, and is in good condition at the time of pick up. Your acceptance and confirmation of any shipment, or your receiving signature on the Waybill or Bill of lading, shall be conclusive that the number of pieces shown on the Waybill or Bill of lading is correct and that lading is in apparent good condition.<br/>
                        iii. Improper or unsafe loading or unloading of any shipment, or, where you are not required to load, failure to determine, to the extent you are able to ascertain through ordinary inspection, that each shipment was properly loaded.<br/>
                        iv. Shipment of any loads in violation of applicable weight, axle, or other applicable laws and regulations, or shipper’s specific instructions.<br/>
                        v. Suspension of service at any time after loaded dispatch, or delay of service by stopover for any reason that would leave your equipment and the cargo unattended without adequate security as a prudent Carrier would or as is otherwise required under your insurance policies.<br/>
                        vi. Acceptance of any shipments for which you cannot comply with applicable temperature requirements, and any damage as a result of failure to comply with such requirements.</p>






					








				
				</Container>
			</Container>

		</div>
	);
}

export default TermsCondition;
