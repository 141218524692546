import axios from "axios";
import isLoggedIn from './isLoggedIn';
  // const URL = "http://localhost:4002/api/v1/admin/"
	const URL = "https://api.amabillstechnologies.com/api/v1/admin/"


const token = localStorage.getItem('token');
console.log('token', token);
const header = {
	'Authorization': 'Bearer ' + token,
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Methods': 'GET, PUT, PATCH, POST,DELETE, OPTIONS',
}


class ResourceService {

	addRecord(path, data) {

		return axios.post(URL + path, data, {
			headers: header
		}).then(result => {
			return result.data;

		}).catch(err => {
			console.log("error", err);

			if (err && err.code === 'ERR_NETWORK') {
				throw new Error(err.message + ', Kindly check your internet connection.');
			}

			if (err.response && err.response.data.message === 'Sorry, you must provide a valid token.') {
				localStorage.removeItem('user');
				localStorage.removeItem('token')
				return isLoggedIn.isLoggedIn();
			}

			if (err && err.code === 'ERR_BAD_REQUEST') {
				if (err.response.data && !(err.response.data instanceof Object)) {
					throw new Error('Invalid endpoint');
				}
				if (err.response.data.status === false && err.response.data.code === 422) {
					console.log('err 1', err.response.data.error)
					throw new Error(err.response.data.error);
				}

				if(err.response.data.message){
					throw new Error(err.response.data.message);
				}

				throw new Error(err.response.data.error);
			}
			
			throw new Error(err);
		});
	};

	
	addRecordWithImage(path, data) {

		const header = {
			'Authorization': 'Bearer ' + token,
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
			'Content-Type': 'multipart/form-data',
		}

		return axios.post(URL + path, data, {
			headers: header
		}).then(result => {
			return result.data;

		}).catch(err => {
			console.log("error", err);

			if (err && err.code === 'ERR_NETWORK') {
				throw new Error(err.message + ', Kindly check your internet connection.');
			}

			if (err.response && err.response.data.message === 'Sorry, you must provide a valid token.') {
				localStorage.removeItem('user');
				localStorage.removeItem('token')
				return isLoggedIn.isLoggedIn();
			}

			if (err && err.code === 'ERR_BAD_REQUEST') {
				if (err.response.data && !(err.response.data instanceof Object)) {
					throw new Error('Invalid endpoint');
				}
				if (err.response.data.status === false && err.response.data.code === 422) {
					console.log('err 1', err.response.data.error)
					throw new Error(err.response.data.error);
				}

				if(err.response.data.message){
					throw new Error(err.response.data.message);
				}

				throw new Error(err.response.data.error);
			}
			
			throw new Error(err);
		});
	};

	listRecord(path) {
		return axios.get(URL + path, {
			headers: header
		}).then(result => {
			return result.data;
		}).catch(err => {

			if (err && err.code === 'ERR_NETWORK') {
				throw new Error(err.message + ', Kindly check your internet connection.');
			}

			if (err.response && err.response.data.message === 'Sorry, you must provide a valid token.') {
				localStorage.removeItem('user');
				localStorage.removeItem('token')
				return isLoggedIn.isLoggedIn(); 
			}
			
			if (err && err.code === 'ERR_BAD_REQUEST') {
				if (err.response.data && !(err.response.data instanceof Object)) {
					throw new Error('Invalid endpoint');
				}
				if (err.response.data.status === false && err.response.data.code === 422) {
					console.log('err 1', err.response.data.error)
					throw new Error(err.response.data.error);
				}

				if(err.response.data.message){
					throw new Error(err.response.data.message);
				}

				throw new Error(err.response.data.error);
			}
			
			throw new Error(err);
		});
	}

	getRecord(path) {
		return axios.get(URL + path, {
			headers: header
		}).then(result => {
			return result.data;
		}).catch(err => {

			if (err && err.code === 'ERR_NETWORK') {
				throw new Error(err.message + ', Kindly check your internet connection.');
			}

			if (err.response && err.response.data.message === 'Sorry, you must provide a valid token.') {
				localStorage.removeItem('user');
				localStorage.removeItem('token')
				return isLoggedIn.isLoggedIn();
			}
			

			if (err && err.code === 'ERR_BAD_REQUEST') {
				if (err.response.data && !(err.response.data instanceof Object)) {
					throw new Error('Invalid endpoint');
				}
				if (err.response.data.status === false && err.response.data.code === 422) {
					console.log('err 1', err.response.data.error)
					throw new Error(err.response.data.error);
				}

				if(err.response.data.message){
					throw new Error(err.response.data.message);
				}

				throw new Error(err.response.data.error);
			}
			
			throw new Error(err);
		})
	}

	updateRecord(path, data) {

		return axios.patch(URL + path, data, {
			headers: header
		}).then(result => {
			return result.data;

		}).catch(err => {
			console.log(err);

			if (err && err.code === 'ERR_NETWORK') {
				throw new Error(err.message + ', Kindly check your internet connection.');
			}

			if (err.response && err.response.data.message === 'Sorry, you must provide a valid token.') {
				localStorage.removeItem('user');
				localStorage.removeItem('token')
				return isLoggedIn.isLoggedIn();
			}
			

			if (err && err.code === 'ERR_BAD_REQUEST') {
				if (err.response.data && !(err.response.data instanceof Object)) {
					throw new Error('Invalid endpoint');
				}
				if (err.response.data.status === false && err.response.data.code === 422) {
					console.log('err 1', err.response.data.error)
					throw new Error(err.response.data.error);
				}

				if(err.response.data.message){
					throw new Error(err.response.data.message);
				}

				throw new Error(err.response.data.error);
			}
			
			throw new Error(err);
		});
	};

	updateRecordWithImage(path, data) {

		const header = {
			'Authorization': 'Bearer ' + token,
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
			'Content-Type': 'multipart/form-data',
		}

		return axios.patch(URL + path, data, {
			headers: header
		}).then(result => {
			return result.data;

		}).catch(err => {
			console.log(err);

			if (err && err.code === 'ERR_NETWORK') {
				throw new Error(err.message + ', Kindly check your internet connection.');
			}

			if (err.response && err.response.data.message === 'Sorry, you must provide a valid token.') {
				localStorage.removeItem('user');
				localStorage.removeItem('token')
				return isLoggedIn.isLoggedIn();
			}
			

			if (err && err.code === 'ERR_BAD_REQUEST') {
				if (err.response.data && !(err.response.data instanceof Object)) {
					throw new Error('Invalid endpoint');
				}
				if (err.response.data.status === false && err.response.data.code === 422) {
					console.log('err 1', err.response.data.error)
					throw new Error(err.response.data.error);
				}

				if(err.response.data.message){
					throw new Error(err.response.data.message);
				}

				throw new Error(err.response.data.error);
			}
			
			throw new Error(err);
		});
	};

	deleteRecord(path, data) {
		return axios.post(URL + path, data, {
			headers: header
		}).then(result => {
			return result.data;

		}).catch(err => {
			console.log(err);

			if (err && err.code === 'ERR_NETWORK') {
				throw new Error(err.message + ', Kindly check your internet connection.');
			}

			if (err.response && err.response.data.message === 'Sorry, you must provide a valid token.') {
				localStorage.removeItem('user');
				localStorage.removeItem('token')
				return isLoggedIn.isLoggedIn();
			}
			

			if (err && err.code === 'ERR_BAD_REQUEST') {
				if (err.response.data && !(err.response.data instanceof Object)) {
					throw new Error('Invalid endpoint');
				}
				if (err.response.data.status === false && err.response.data.code === 422) {
					console.log('err 1', err.response.data.error)
					throw new Error(err.response.data.error);
				}

				if(err.response.data.message){
					throw new Error(err.response.data.message);
				}

				throw new Error(err.response.data.error);
			}
			
			throw new Error(err);
		});
	};


}

const resourceService = new ResourceService();
export default resourceService;