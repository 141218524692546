import React from "react";
import styled from "styled-components";
import { Container, Form, Button, Spinner } from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Auth from "./services/auth";
import { useNavigate } from "react-router-dom";
import { useState } from "react";


const StyledForm = styled(Form)`
  margin-top: 2%;
  width: 100%;
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;


  @media (min-width: 576px) {
    width: 90%;
  }

  @media (min-width: 768px) {
    width: 40%;
  }
  @media (min-width: 1200px) {
    width: 30%;
  }
`;

function LoginPage() {

  const naviate = useNavigate();
  const [form, setForm] = useState({
    email: "", password: ""
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleOnchange = (setState) => (event) => {
    const { value, name } = event.target;
    setState((previous) => ({
      ...previous, [`${name}`]: value,
    }));
  };

  const handleSubmit = async (action) => {
    action.preventDefault();
    setIsLoading(true);

    try {

      const loginRes = await Auth.postUserAuth("auth/login", form);
      if (loginRes) {
        if (loginRes.status && loginRes.status === true) {
          toast.success("Authentication successfull");
          setTimeout(() => {
            naviate("/ld-admin/dashboard");
          }, 3500)
        } else { toast.error(loginRes) }
      } else {
        toast.error("Error logging in! Try again.")
      }
    } catch (error) {

      if(error.message){
        return toast.error(error.message);
      }
      console.log('ERROR:: ', error)
      return toast.error("Error logging in! Try again.")
    } finally{
      setIsLoading(false);
    }
  }


  return (
    <Container
      fluid
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#EFF1F7",
        width: "100%",
        height: "100vh",
      }}
    >
      <ToastContainer />
      <StyledForm onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className="formlabel">Email</Form.Label>
          <Form.Control
            required
            onChange={handleOnchange(setForm)}
            className="formcontrol"
            type="email"
            name="email"
            placeholder="Enter your email"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className="formlabel">Password</Form.Label>
          <Form.Control
            required
            onChange={handleOnchange(setForm)}
            className="formcontrol"
            type="password"
            name="password"
            placeholder="Enter your password"
          />
           <span style={{fontFamily:'jost',fontSize:0.85+'rem',color:'grey'}}>Forget Password ?</span>
        </Form.Group>
       

        <br />
        <Button
          type="submit"
          variant="primary"
          style={{
            backgroundColor: "#F36F2E",
            borderColor: "#F14810",
            borderRadius: "26px",
          }}
          className="buttonform"
          size="md"
          disabled={isLoading}
        >

          {isLoading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span
                style={{
                  fontSize: 0.8 + "rem",
                  marginLeft: 0.3 + "rem",
                  marginRight: 0.3 + "rem",
                }}
              >
                Processing...
              </span>
            </>
          ) : (
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.3 + "rem",
                marginRight: 0.3 + "rem",
              }}
            >
              LOGIN
            </span>
          )}

          {/* <span
            style={{
              fontSize: "0.8rem",
              marginLeft: "0.6rem",
              marginRight: "0.6rem",
            }}
          >
            LOGIN
          </span> */}
        </Button>
      </StyledForm>
    </Container>
  );
}

export default LoginPage;
