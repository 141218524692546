import axios from 'axios'
// const URL = "http://localhost:4002/api/v1/"
const URL = "https://api.amabillstechnologies.com/api/v1/"

const header = {
	// 'Authorization': 'Bearer ' + token,
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}

class ContactUsService {


	createRequest(path, data) {
		return axios.post(URL + path, data, {
			headers: header,
		}).then(result => {
			if (result && result.data) {
				return result.data;
			}
			return result;
		}).catch(err => {

			console.log('error', err)

			if (err && err.code === 'ERR_NETWORK') {
				throw new Error(err.message + ', Kindly check your internet connection.');
			}

			if (err && err.code === 'ERR_BAD_REQUEST') {
				if (err.response.data && !(err.response.data instanceof Object)) {
					throw new Error('Invalid endpoint');
				}
				if (err.response.data.status === false && err.response.data.code === 422) {
					console.log('err 1', err.response.data.error)
					throw new Error(err.response.data.error);
				}

				if (err.response.data.message) {
					throw new Error(err.response.data.message);
				}

				throw new Error(err.response.data.error);
			}

			throw new Error(err);
		});
	};


	// monitor(path, data) {

	// 	axios.get('https://api.ipify.org?format=json')
	// 		.then(response => {
	// 			const ipAddress = response.data.ip;
	// 			console.log(ipAddress);
	// 		})
	// 		.catch(error => {
	// 			console.error('Error fetching IP address:', error);
	// 	});

	// 	//I need to make the above call before making the below call


	// 	const form = {
	// 		page: window.location.pathname === "/" ? "homepage" : window.location.pathname.replace("/", ""),
	// 		machine: navigator.userAgent,
	// 	}


	// 	return axios.post(URL + 'analytics/track', form, {
	// 		headers: header,
	// 	}).then(result => {
	// 		if (result && result.data) {
	// 			return result.data;
	// 		}
	// 		return result;
	// 	}).catch(err => {

	// 		console.log('error', err)

	// 		if (err && err.code === 'ERR_NETWORK') {
	// 			throw new Error(err.message + ', Kindly check your internet connection.');
	// 		}

	// 		if (err && err.code === 'ERR_BAD_REQUEST') {
	// 			if (err.response.data && !(err.response.data instanceof Object)) {
	// 				throw new Error('Invalid endpoint');
	// 			}
	// 			if (err.response.data.status === false && err.response.data.code === 422) {
	// 				console.log('err 1', err.response.data.error)
	// 				throw new Error(err.response.data.error);
	// 			}

	// 			if (err.response.data.message) {
	// 				throw new Error(err.response.data.message);
	// 			}

	// 			throw new Error(err.response.data.error);
	// 		}

	// 		throw new Error(err);
	// 	});
	// };



	async monitor(path, data) {
		try {
			const ipResponse = await axios.get('https://api.ipify.org?format=json');
			const ipAddress = ipResponse.data.ip;
			console.log(ipAddress);
	
			const form = {
				page: window.location.pathname === "/" ? "homepage" : window.location.pathname.replace("/", ""),
				machine: navigator.userAgent,
				ip: ipAddress
			};
			console.log('form ', form)
	
			const result = await axios.post(URL + 'analytics/track', form, {
				headers: header
			});
	
			if (result && result.data) {
				return result.data;
			}
			return result;
		} catch (err) {
			console.error('Error in monitor function:', err);
			if (err.code === 'ERR_NETWORK') {
				throw new Error(err.message + ', Kindly check your internet connection.');
			}
			if (err.code === 'ERR_BAD_REQUEST') {
				if (err.response.data && !(err.response.data instanceof Object)) {
					throw new Error('Invalid endpoint');
				}
				if (err.response.data.status === false && err.response.data.code === 422) {
					console.log('err 1', err.response.data.error);
					throw new Error(err.response.data.error);
				}
				if (err.response.data.message) {
					throw new Error(err.response.data.message);
				}
				throw new Error(err.response.data.error);
			}
			throw new Error(err);
		}
	}



	listRecord(path) {
		return axios.get(URL + path, {
			headers: header
		}).then(result => {
			return result.data;
		}).catch(err => {

			if (err && err.code === 'ERR_NETWORK') {
				throw new Error(err.message + ', Kindly check your internet connection.');
			}

			if (err.response && err.response.data.message === 'Sorry, you must provide a valid token.') {
				localStorage.removeItem('user');
				localStorage.removeItem('token')
				// return isLoggedIn.isLoggedIn(); 
			}

			if (err && err.code === 'ERR_BAD_REQUEST') {
				if (err.response.data && !(err.response.data instanceof Object)) {
					throw new Error('Invalid endpoint');
				}
				if (err.response.data.status === false && err.response.data.code === 422) {
					console.log('err 1', err.response.data.error)
					throw new Error(err.response.data.error);
				}

				if (err.response.data.message) {
					throw new Error(err.response.data.message);
				}

				throw new Error(err.response.data.error);
			}

			throw new Error(err);
		});
	}

	getRecord(path) {
		return axios.get(URL + path, {
			headers: header
		}).then(result => {
			return result.data;
		}).catch(err => {

			if (err && err.code === 'ERR_NETWORK') {
				throw new Error(err.message + ', Kindly check your internet connection.');
			}

			if (err.response && err.response.data.message === 'Sorry, you must provide a valid token.') {
				localStorage.removeItem('user');
				localStorage.removeItem('token')
				// return isLoggedIn.isLoggedIn();
			}


			if (err && err.code === 'ERR_BAD_REQUEST') {
				if (err.response.data && !(err.response.data instanceof Object)) {
					throw new Error('Invalid endpoint');
				}
				if (err.response.data.status === false && err.response.data.code === 422) {
					console.log('err 1', err.response.data.error)
					throw new Error(err.response.data.error);
				}

				if (err.response.data.message) {
					throw new Error(err.response.data.message);
				}

				throw new Error(err.response.data.error);
			}

			throw new Error(err);
		})
	}

}

const contactUsServiceInstance = new ContactUsService();
export default contactUsServiceInstance;