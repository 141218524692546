import React from "react";
import { Container, Row, Button } from "react-bootstrap";
import { easeOut, motion } from "framer-motion";
import "../../homepage/homepage.css";


import { Link } from "react-router-dom";



function Introduction() {
  

  return (
    <Container fluid className="introsection">
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: -5, opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.5, timingFunction: easeOut }}
        viewport={{ once: true }}
      >
        <Container className="introinnersection text-center">
          <Row>
            <h1 className="introheader" style={{ color: "#F36F2E" }}>
              <strong>Introducing the Loda</strong>
            </h1>
            <p className="introparagraph">
            In today’s fast-paced world, convenience and reliability are key to moving 
            people and goods efficiently. The Loda is your all-in-one solution, connecting users 
            to a network of trusted transporters for truck hailing, ride-hailing, tricycle services, 
            and deliveries. Whether you're booking a ride, transporting goods, or managing shipments, 
            The Loda makes it seamless. With real-time tracking, transparent pricing, and a wide network 
            of skilled drivers, we ensure that every journey, big or small, is handled with care. Join us 
            and revolutionize the way you move and transport with ease.
            </p>
            <br />
            <div className="buttoncontainer">
              <Link to="/aboutus">
                <Button
                  className="readmorebtn"
                  variant="outline-primary"
                  size="md"
                >
                  <span
                    style={{
                      fontSize: 0.8 + "rem",
                      marginLeft: 0.4 + "rem",
                      marginRight: 0.4 + "rem",
                    }}
                  >
                    Read More
                  </span>
                </Button>
              </Link>{" "}
           
           
            </div>
          </Row>
        </Container>
      </motion.div>
    </Container>
  );
}

export default Introduction;
