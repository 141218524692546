import React, { useEffect, useRef } from "react";
import StickyNavbar from '../reuse-component/Navbar/StickyNavbar';

import FooterSection from "../homepage/component/FooterSection";
import TermsCondition from "./component/TermsCondition";
import Services from '../../services/contactUs'

function TermsConditionx() {

	const monitoringInitiated = useRef(false);
  useEffect(() => {
    if (!monitoringInitiated.current) {
      Services.monitor();
      monitoringInitiated.current = true;
    }
  }, []);
	
	return (
		<>
			<StickyNavbar />
			<TermsCondition />
			<FooterSection />
		</>
	);
}

export default TermsConditionx;
