import React, {useEffect, useRef} from 'react';
import HeroSection from './component/HeroSection';
import StickyNavbar from '../reuse-component/Navbar/StickyNavbar';
import OtherSection from './component/OtherSection';
import DownloadApp from '../homepage/component/DownloadApp';
import Contactus from '../homepage/component/Contactus';
import FooterSection from '../homepage/component/FooterSection';
import Products from '../homepage/component/Products';
import MissionVission from './component/MissionVission';
import Services from '../../services/contactUs' 

function AboutUs() {
  const monitoringInitiated = useRef(false);
  useEffect(() => {
    if (!monitoringInitiated.current) {
      Services.monitor();
      monitoringInitiated.current = true;
    }
  }, []);

  return (
    <>
        <StickyNavbar />
        <HeroSection />
        <MissionVission />
        <OtherSection />
        <Products />
        <DownloadApp />
        <Contactus />
        <FooterSection />
      
    </>
  )
}

export default AboutUs
