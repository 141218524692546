import React, {useEffect, useRef} from 'react'
import StickyNavbar from '../reuse-component/Navbar/StickyNavbar';
import HeroSection from './component/HeroSection';
import Introduction from './component/Introduction';
import Products from './component/Products';
import Category from './component/Category';
import Features from './component/Features';
import News from './component/News';
import DownloadApp from './component/DownloadApp';
import Question from './component/Question';
import Contactus from './component/Contactus';
import FooterSection from './component/FooterSection';
import OtherSection from '../WhyLoda/component/OtherSection';
import Services from '../../services/contactUs'
import OurServices from './component/OurServices';

function HomePage() {
  const monitoringInitiated = useRef(false);

  useEffect(() => {
    if (!monitoringInitiated.current) {
      Services.monitor();
      monitoringInitiated.current = true;
    }
  }, []);

  return (
    <>
      <StickyNavbar />
      <HeroSection />
      <Introduction />
      <OurServices />
      <OtherSection />
      <Products />
      <Features />
      <News />
      <DownloadApp />
      <Question />
      <Contactus />
      <FooterSection />
    </>
  );
}

export default HomePage;
