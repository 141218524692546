import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import AnimatedWrapper from '../../homepage/component/AnimatedWrapper'

function MissionVission() {
  return (
    <Container fluid style={{marginBottom:6+"rem"}} className='otherSection'>

      <Row>
        <h2 style={{fontWeight:400,fontFamily:"jost"}}>We engage In The Business of <strong style={{color:"#F36F2E"}}> Logistics ICT Services</strong> and<strong style={{color:"#F36F2E"}}> <br/>Logistics Software Technology.</strong></h2>
        <p  style={{fontsize:0.8+"rem", fontFamily:"jost"}}>With our cutting-edge technology and industry knowledge, we empower businesses to optimize their operations and enhance efficiency in the ever-evolving logistics landscape.</p>
      </Row><br/><br/>
      <Row>
        <Col xs={12} md={6} lg={6} >
        <AnimatedWrapper delay={0.2}>
         <div style={{display:"flex", flexDirection:"row",gap:10,alignItems:"center"}}>
         <svg width="30" height="30" viewBox="0 0 133 134" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M66.5 3V3C31.4299 3 3 31.9299 3 67V67C3 102.07 31.4299 131 66.5 131V131C101.57 131 130 102.57 130 67.5V48" stroke="#F36F2E" stroke-width="8"></path>
                <path d="M66.625 19V19C40.3224 19 19 40.6974 19 67V67C19 93.3026 40.3224 115 66.625 115V115C92.9276 115 114.25 93.6776 114.25 67.375V52.75" stroke="#F36F2E" stroke-width="8"></path>
                <path d="M66.625 38V38C51.5558 38 39.3398 50.4308 39.3398 65.5V65.5C39.3398 80.5692 51.5558 93 66.625 93V93C81.6942 93 93.9102 80.784 93.9102 65.7148V57.3359" stroke="#F36F2E" stroke-width="8"></path>
                <path d="M69 59L93.5 35M93.5 35V21.4378C93.5 21.1587 93.6166 20.8923 93.8217 20.703L111.322 4.54918C111.962 3.95789 113 4.41223 113 5.28399V17H124.957C125.865 17 126.303 18.1135 125.638 18.7322L106.357 36.6677C106.132 36.8774 105.822 36.9716 105.518 36.9229L93.5 35Z" stroke="#F36F2E" stroke-width="8"></path>
          </svg>
          <h3 style={{fontSize:2.1+"rem",fontWeight:"bold",fontFamily:"jost"}}>Mission Statement:
          </h3>
         </div>
         <p style={{fontSize:0.85+"rem",fontFamily:"jost",lineHeight:197+"%"}}>
         To transform the way Africa moves and transacts by delivering seamless, reliable, and innovative 
         transportation and financial solutions that empower individuals, businesses, and communities to thrive.


         </p>
         </AnimatedWrapper>
        </Col>
        <Col xs={12} md={6} lg={6} >
          <AnimatedWrapper delay={0.4}>
         <div style={{display:"flex", flexDirection:"row",gap:10,alignItems:"center"}}>
         <svg width="30" height="30" viewBox="0 0 127 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M63.5 87.5C46.4629 87.5 31.9478 77.7532 25.7264 64C31.9478 50.2468 46.4629 40.5 63.5 40.5C80.5371 40.5 95.0522 50.2468 101.274 64C95.0522 77.7532 80.5371 87.5 63.5 87.5ZM42.5909 64C42.5909 75.0884 52.1014 83.8333 63.5 83.8333C74.8986 83.8333 84.4091 75.0884 84.4091 64C84.4091 52.9116 74.8986 44.1667 63.5 44.1667C52.1014 44.1667 42.5909 52.9116 42.5909 64ZM54.9545 64C54.9545 59.7657 58.6249 56.1 63.5 56.1C68.3751 56.1 72.0455 59.7657 72.0455 64C72.0455 68.2343 68.3751 71.9 63.5 71.9C58.6249 71.9 54.9545 68.2343 54.9545 64Z" stroke="#F36F2E" stroke-width="8"></path>
                <rect x="2.5" y="2.5" width="122" height="123" rx="61" stroke="#F36F2E" stroke-width="8"></rect>
              </svg>
          <h3 style={{fontSize:2.1+"rem",fontWeight:"bold",fontFamily:"jost"}}>Vision Statement:
          </h3>
         </div>
         <p style={{fontSize:0.85+"rem",fontFamily:"jost",lineHeight:197+"%"}}>
         To be the trusted gateway for Africa’s mobility and financial empowerment, driving a 
         future where technology unlocks limitless opportunities for growth and connection 
         across the continent.

         </p>
         </AnimatedWrapper>
        </Col>
      </Row>
    </Container>
  )
}

export default MissionVission
