import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { easeOut, motion } from "framer-motion";

import mark from "../../../assets/mark.svg";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import "../../WhyLoda/whyloda.css";

import whyShipperData from "./whyShipperData.json";

import whyDriverData from "./whyDriverData.json";

import whyTransporterData from "./whyTransporterData.json";

import whyRiderData from "./whyRiderData.json";

function OtherSection() {
  return (
    <Container fluid className="otherSection" style={{marginTop:'6rem'}}>
       <motion.div
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: -5, opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.5, timingFunction: easeOut }}
        viewport={{ once: true }}
      >
      <div
        style={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h3 className="otherSectionTextHead" style={{ color: "#F36F2E" }}>
          <strong>Our Value Preposition</strong>
        </h3>
        <p
          
          style={{
           
            fontSize: 0.85 + "rem",
            color: "#1D2221",
            lineHeight: 190 + "%",
          }}
        >
          THE LODA offers logistics solution that tackles a wide range of
          challenges, providing unparalleled value <br/> to fleet owners, businesses,
          drivers, and individuals:
        </p>
      </div>
      </motion.div>



      <Tabs
        defaultActiveKey="rider"
        id="justify-tab-example"
        className="mb-3 producttab"
        justify
      >
        <br />
        <Tab
          style={{ marginTop: 2.5 + "rem" }}
          eventKey="rider"
          title="Rider"
        >
          <Container fluid>
            <Row>
              {whyRiderData.map((item) => (
                
                <Col xs={12} md={6} lg={6}>
                   <motion.div
                 initial={{ y: 20, opacity: 0 }}
                 whileInView={{ y: -5, opacity: 1 }}
                 transition={{ duration: 0.3, delay: item.num, timingFunction: easeOut }}
                 viewport={{ once: true }}
               >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 5,
                      alignItems: "flex-start",
                    }}
                  >
                    <Image src={mark} />
                    <div>
                      <h5 style={{ fontSize: 1.1 + "rem", fontFamily: "jost" }}>
                        <strong>{item.Header}</strong>
                      </h5>
                      <p style={{ fontSize: 0.85 + "rem", fontFamily: "jost" }}>
                        {item.Content}
                      </p>
                    </div>
                  </div>
                  </motion.div>
                </Col>
                
              ))}
            </Row>
          </Container>
        </Tab>
        
        <Tab
          style={{ marginTop: 2.5 + "rem" }}
          eventKey="shipper"
          title="Shipper"
        >
          <Container fluid>
            <Row>
              {whyShipperData.map((item) => (
                
                <Col xs={12} md={6} lg={6}>
                   <motion.div
                 initial={{ y: 20, opacity: 0 }}
                 whileInView={{ y: -5, opacity: 1 }}
                 transition={{ duration: 0.3, delay: item.num, timingFunction: easeOut }}
                 viewport={{ once: true }}
               >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 5,
                      alignItems: "flex-start",
                    }}
                  >
                    <Image src={mark} />
                    <div>
                      <h5 style={{ fontSize: 1.1 + "rem", fontFamily: "jost" }}>
                        <strong>{item.Header}</strong>
                      </h5>
                      <p style={{ fontSize: 0.85 + "rem", fontFamily: "jost" }}>
                        {item.Content}
                      </p>
                    </div>
                  </div>
                  </motion.div>
                </Col>
               
              ))}
            </Row>
          </Container>
        </Tab>

       

        <Tab
          style={{ marginTop: 2.5 + "rem" }}
          eventKey="driver"
          title="Driver"
        >
          <Container fluid>
            <Row>
              {whyDriverData.map((item) => (
                
                <Col xs={12} md={6} lg={6}>
                   <motion.div
                 initial={{ y: 20, opacity: 0 }}
                 whileInView={{ y: -5, opacity: 1 }}
                 transition={{ duration: 0.3, delay: item.num, timingFunction: easeOut }}
                 viewport={{ once: true }}
               >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 5,
                      alignItems: "flex-start",
                    }}
                  >
                    <Image src={mark} />
                    <div>
                      <h5 style={{ fontSize: 1.1 + "rem", fontFamily: "jost" }}>
                        <strong>{item.Header}</strong>
                      </h5>
                      <p style={{ fontSize: 0.85 + "rem", fontFamily: "jost" }}>
                        {item.Content}
                      </p>
                    </div>
                  </div>
                  </motion.div>
                </Col>
                
              ))}
            </Row>
          </Container>
        </Tab>

        <Tab
          style={{ marginTop: 2.5 + "rem" }}
          eventKey="transporter"
          title="Transporter"
        >
          <Container fluid>
            <Row>
              {whyTransporterData.map((item) => (
                
                <Col xs={12} md={6} lg={6}>
                   <motion.div
                 initial={{ y: 20, opacity: 0 }}
                 whileInView={{ y: -5, opacity: 1 }}
                 transition={{ duration: 0.3, delay: item.num, timingFunction: easeOut }}
                 viewport={{ once: true }}
               >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 5,
                      alignItems: "flex-start",
                    }}
                  >
                    <Image src={mark} />
                    <div>
                      <h5 style={{ fontSize: 1.1 + "rem", fontFamily: "jost" }}>
                        <strong>{item.Header}</strong>
                      </h5>
                      <p style={{ fontSize: 0.85 + "rem", fontFamily: "jost" }}>
                        {item.Content}
                      </p>
                    </div>
                  </div>
                  </motion.div>
                </Col>
                
              ))}
            </Row>
          </Container>
        </Tab>
        

      </Tabs>
    </Container>
  );
}

export default OtherSection;
