
class AuthService {

	async isLoggedIn() {
			const user = localStorage.getItem('user');

			if (!user) {
					return window.location.href = '/secret';
			}

			const token = JSON.parse(user);
			return token.data.token;
	};
}

const authMonitor = new AuthService()
export default authMonitor;