import React from 'react'
import {  motion } from "framer-motion";
import { Row, Col, Container, Image } from "react-bootstrap";


import { easeOut } from "framer-motion";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import rideone from "../../../assets/rideone.jpg"
import ridetwo from "../../../assets/ridetwo.jpg"

import kekeone from "../../../assets/kekeone.png"
import keketwo from "../../../assets/keketwo.png"



import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";



import "../../homepage/homepage.css";

const Dataset = [
  {
    id: "1",
    captionTitle: "Sided Body",
    captionDescription:
      "Versatile hauling solutions with secure side panels. Reliable delivery for your cargo needs.",
    imageUrl: "/assets/sidedbody.png",
  },
  {
    id: "2",
    captionTitle: "Low Bed",
    captionDescription:
      "Sturdy, stable transport for heavy machinery and oversized cargo. Optimal security for valuable equipment.",
    imageUrl: "/assets/lowbed.png",
  },
  {
    id: "3",
    captionTitle: "Package Truck",
    captionDescription:
      "Streamlined delivery services with customizable compartments. Hassle-free shipping for your packages.",
    imageUrl: "/assets/package.png",
  },
  {
    id: "4",
    captionTitle: "Container Truck",
    captionDescription:
      "Heavy-load transport made easy with spacious compartments. Secure, efficient logistics for large volumes.",
    imageUrl: "/assets/container.png",
  },
  {
    id: "5",
    captionTitle: "Flatbed Truck",
    captionDescription:
      "Versatile loading options for large, bulky items. Safe, efficient transportation with an open design.",
    imageUrl: "/assets/flatbed.png",
  },
  {
    id: "6",
    captionTitle: "Oil Tanker",
    captionDescription:
      "Spill-proof transportation for oil and petroleum products. Specialized fleet for secure oil delivery.",
    imageUrl: "/assets/oiltanker.png",
  },
  {
    id: "7",
    captionTitle: "Half-body Truck",
    captionDescription:
      "Efficient transportation for smaller cargo loads. Maneuverable handling for efficeient deliveries.",
    imageUrl: "/assets/halfbody.png",
  },
  {
    id: "8",
    captionTitle: "pick-up Truck",
    captionDescription:
      "Quick, convenient transportation for goods of all sizes. Reliable delivery with robust performance.",
    imageUrl: "/assets/pickup.png",
  },
  {
    id: "9",
    captionTitle: "Van",
    captionDescription:
      "Cost-effective solution for small to medium-sized deliveries. Versatile cargo space for efficient logistics.",
    imageUrl: "/assets/van.png",
  },
];





function OurServices() {

 
  

 


  return (
    <Container fluid className="otherSection" style={{marginTop:'6rem'}}>
    <motion.div
     initial={{ y: 20, opacity: 0 }}
     whileInView={{ y: -5, opacity: 1 }}
     transition={{ duration: 0.3, delay: 0.5, timingFunction: easeOut }}
     viewport={{ once: true }}
   >
   <div
     style={{
       textAlign: "center",
       justifyContent: "center",
       alignItems: "center",
     }}
   >
     <h3 className="otherSectionTextHead" style={{ color: "#F36F2E" }}>
       <strong>Our Services</strong>
     </h3>
     <p
       
       style={{
        
         fontSize: 0.85 + "rem",
         color: "#1D2221",
         lineHeight: 190 + "%",
       }}
     >
      Discover the Transport Solutions You Need! At The Loda, we’re all 
      about making your <br/>transportation experience easy and effective.
     </p>
   </div>
   </motion.div>



   <Tabs
     defaultActiveKey="shipper"
     id="justify-tab-example"
     className="mb-3 producttab"
     justify
   >
     <br />
    
     
     <Tab
       style={{ marginTop: 2.5 + "rem" }}
       eventKey="shipper"
       title="Truck"
     >
       <Container fluid>
         
         <div style={{marginBottom:'2rem'}}>
          <h5>Truck Hailing</h5>
          <p style={{fontSize:'0.85rem'}}>
          Get your truck on the road and accept diverse transport requests. It’s a simple way to keep your fleet busy and your earnings up.
          </p>
          </div>
          <Container fluid className="figurebase" >
        <motion.div
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: -5, opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.5, timingFunction: easeOut }}
        viewport={{ once: true }}
      >

      <div>
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 900,
              },
              items: 3,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 550,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
            Semitab: {
              breakpoint: {
                max: 850,
                min: 550,
              },
              items: 2,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 850,
              },
              items: 3,
              partialVisibilityGutter: 30,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {Dataset.map((item) => (
            <div  style={{marginLeft:0.5+'rem',marginRight:0.5+'rem'}}>
            <figure className="figure-container">
              <Image
                width={100 + "%"}
                src={item.imageUrl}
                alt="Description of the image"
              />
              <figcaption className="caption">
                <h6 className="captionheader">{item.captionTitle}</h6>
                <p className="captiondescription">{item.captionDescription}</p>
              </figcaption>
            </figure>
            </div>
          ))}
        </Carousel>
      </div>
      </motion.div>
    </Container>
         
       </Container>
     </Tab>

     <Tab
       style={{ marginTop: 2.5 + "rem" }}
       eventKey="rider"
       title="Ride"
     >
       <Container fluid>
         
         <div style={{marginBottom:'2rem'}}>
          <h5>Ride Hailing</h5>
          <p style={{fontSize:'0.85rem'}}>
            Join our ride-hailing community! Drive passengers where they need 
            to go and enjoy flexible hours that fit your life.
          </p>
          </div>
          <Row>
    <Col xs={12} md={6} lg={6}>
        <Image src={rideone} width={'100%'} alt="image"  />
    </Col>
    <Col xs={12} md={6} lg={6}>
        <Image src={ridetwo} width={'100%'} className="hide-on-mobile" alt="image" />
    </Col>
</Row>

         
       </Container>
     </Tab>

    

     <Tab
       style={{ marginTop: 2.5 + "rem" }}
       eventKey="driver"
       title="Tricycle"
     >
        <Container fluid>
         
         <div style={{marginBottom:'2rem'}}>
          <h5>Tricycle Hailing</h5>
          <p style={{fontSize:'0.85rem'}}>
          Use your Keke Napep to provide quick and affordable rides. Connect with 
          passengers easily and earn on your own schedule.

          </p>
          </div>
          <Row>
        <Col xs={12} md={6} lg={6}>
        <Image  src={kekeone} width={100+'%'}  alt="image" />
        </Col>
        <Col xs={12} md={6} lg={6}>
        <Image  src={keketwo} width={100+'%'} className="hide-on-mobile" alt="image" />
        </Col>
      
        
         </Row>
         
       </Container>
     </Tab>

     <Tab
       style={{ marginTop: 2.5 + "rem" }}
       eventKey="transporter"
       title="Delivery"
     >
        <Container fluid>
         
         <div style={{marginBottom:'2rem'}}>
          <h5>Delivery <span style={{fontSize:'0.7rem',color:'orange'}}>Coming soon</span></h5>
          <p style={{fontSize:'0.85rem'}}>
          Stay tuned for our new delivery service! Businesses will soon be 
          able to send packages quickly and reliably with just a few taps.
          </p>
          </div>
        <div>
         
         </div>
         
       </Container>
     </Tab>
     

   </Tabs>
 </Container>
  )
}

export default OurServices
