import React from 'react';
import pagenotfound from '../../assets/pagenotfound.svg';
import{ Image } from 'react-bootstrap';
import { Link} from 'react-router-dom';

function Notfound() {
  return (
    <div style={{display:'flex', flexDirection:'column', justifyContent:'center',alignItems:'center',marginTop:5+'rem'}}>
      
      <Image src={pagenotfound} width={380} fluid />
      <p style={{fontSize:0.82+'rem'}}>The page you are looking for doesn't exist here,</p>
      <p  style={{fontSize:0.82+'rem', marginTop:-1+'rem'}}>click the button below to Navigate to the home-page</p>
      <Link to="/">
      <h6 style={{color:'#f14810'}}>Back</h6>
      </Link>
     
     
      
    </div>
  )
}

export default Notfound;
