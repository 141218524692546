import React, {useEffect, useRef} from 'react'
import StickyNavbar from '../reuse-component/Navbar/StickyNavbar'
import Herosection from './component/Herosection'
import BelowSection from './component/BelowSection'
import Contactus from '../homepage/component/Contactus'
import FooterSection from '../homepage/component/FooterSection'
import Services from '../../services/contactUs'

function Safety() {
  const monitoringInitiated = useRef(false);
  useEffect(() => {
    if (!monitoringInitiated.current) {
      Services.monitor();
      monitoringInitiated.current = true;
    }
  }, []);
  return (
    <>
    <StickyNavbar />
    <Herosection />
    <BelowSection />
    <Contactus />
    <FooterSection />
      
    </>
  )
}

export default Safety
