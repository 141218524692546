import React, { Fragment, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./DataDeletion.css";
import StickyNavbar from "../reuse-component/Navbar/StickyNavbar";
import FooterSection from "../homepage/component/FooterSection";
import "./PrivacyPolicy"
import Services from '../../services/contactUs'
function DataDeletion() {

  const monitoringInitiated = useRef(false);
  useEffect(() => {
    if (!monitoringInitiated.current) {
      Services.monitor();
      monitoringInitiated.current = true;
    }
  }, []);
  
  return (
    <Fragment>
      <StickyNavbar />
      <Container fluid id="hero" className="herosection">
        <Container fluid className="containersectionabt">
          <Row>
            <Col xs={12} md={8} lg={6} style={{marginTop: -4+"rem"}}>
              <h2 style={{ color: '#FFFFFF', fontFamily: 'jost', fontSize: 2.5 + 'rem' }}><strong>Data Deletion Request</strong></h2>
              <p style={{ color: '#FFFFFF', fontSize: 0.75 + 'rem', lineHeight: 224 + '%', fontFamily: 'jost' }} >
                For information on how to request deletion of your account and data
                associated with <strong>The Loda</strong>, please review our Data
                Deletion Policy. This policy outlines the specific data we collect and
                store, what data is deleted upon request, and any applicable retention
                periods. </p>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="headings" style={{ marginTop: -4 + "rem", fontFamily:"jost" }}>
        <h3>Key Points</h3>
        <ul className={styles.keyPoints}>
          <li>
            Easily request account deletion through multiple methods:
            <ul className={styles.deletionMethods}>
              <li>Directly through the app settings (if applicable).</li>

              <li>
                By contacting us at:{" "}
                <span className={styles.redacted}>support@theloda.com</span>{" "}
                (mention your email address in the email for verification).
              </li>
            </ul>
          </li>
          <li>
            We delete data linked to your account upon request, following our
            policy guidelines.
          </li>
          <li>
            The Data Deletion Policy details data retention periods (if
            applicable).
          </li>
        </ul>
        <p>
          For more information, please refer to our{" "}
          <a href="https://theloda.co/privacy">Data Deletion Policy</a>.
        </p>
      </Container>

      <FooterSection />
    </Fragment>
  );
};


export default DataDeletion;