import React from "react";
import { Container, Row, Col, Image } from 'react-bootstrap';

import "../../aboutus/aboutus.css";
import svgmap from '../../../assets/svgmap.png';

function HeroSection() {
  return (
    <div>
      
      <Container fluid id="hero" className="herosection">
        <Container fluid className="containersectionabt">
            <Row>
                <Col xs={12} md={7} lg={7}>
                <h2 style={{color:'#FFFFFF',fontFamily:'jost',fontSize:2.5+'rem'}}><strong>Why Choose THE LODA?</strong></h2>
                <p style={{color:'#FFFFFF', fontSize:0.75+'rem', lineHeight:224+'%', fontFamily:'jost'}} >
                From ride hailing and tricycle services to deliveries and truck transport, The Loda offers 
                a seamless, reliable platform designed to meet your every need. Trust us to get you or your 
                cargo where it needs to be—quickly, safely, and efficiently.

                </p>
                <p style={{color:'#FFFFFF', fontSize:0.75+'rem', lineHeight:224+'%', fontFamily:'jost'}} >
                Experience hassle-free logistics, trusted services, and real solutions that make your transportation 
                needs easier. Here's why THE LODA works for you every day.

                </p>
                </Col>
                <Col xs={12} md={5} lg={5}>
                <center>
                  <Image width={100+"%"} src={svgmap}  alt="image" />
                  </center>
                </Col>
            </Row>
        </Container>
      </Container>
    </div>
  );
}

export default HeroSection;
