import React, { useEffect, useState } from "react";
import { Container, Image, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Services from '../../../services/contactUs'

function News() {

  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchResources = async () => {
      try {

        const response = await Services.listRecord("blogs/list");
        setResources(response.data.data);
        console.log('blogs', response.data);
        setLoading(false);

      } catch (error) {
        console.log('outside error:: ', error);
        setLoading(false);
      }
    };
    fetchResources();
  }, []);

  const defaultImg = '/assets/yam.png';

  const formatDate = (date) => {
    const createdAtDate = new Date(date);
    const options = {weekday: "short",day: "numeric",month: "long",year: "numeric"};
    const formattedDate = createdAtDate.toLocaleDateString(
      undefined, options
    );
    return formattedDate;
  }


  return (
    <Container fluid className="NewsContainer">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1 className="Newsheader">
          <strong>News</strong></h1>

        <Link to={`/blog}`}>
          <Button className="readmorebtn" variant="outline-primary" size="md">
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.4 + "rem",
                marginRight: 0.4 + "rem",
              }}
            >
              Read More
            </span>
          </Button>
        </Link>
      </div>
      <br />
      <Row>
        {loading ? (
          <p>Loading...</p>
        ) : resources.length === 0 ? (
          <p>No Record found</p>
        ) : (

          resources.map((item) => (
            <Col xs={12} sm={12} md={4} lg={4}>
              <Link to={`/blog/${item.slug}`}>
                <div>
                  <div>
                    {item.local_img ? (
                      <Image src={item.local_img} width="100%" height="200px" />
                    ) : (
                      <Image src={defaultImg} width="100%" height="200px" />
                    )}
                  </div>
                  <br />
                  <div>
                    <h6 style={{ color: '#000000' }} ><strong>{item.title}</strong></h6>
                    <span style={{ color: '#616161', fontSize: 0.8 + 'rem', fontWeight: 'bold' }}><em>{formatDate(item.createdAt)}</em></span>
                  </div>
                </div>
              </Link>
            </Col>

          ))
        )
        }
      </Row>
    </Container>
  );
}

export default News;
