import React from "react";
import Carousel from "react-multi-carousel";
import { Image, Container } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";

import { easeOut, motion } from "framer-motion";

import "../../homepage/homepage.css";

const Dataset = [
  {
    id: "1",
    captionTitle: "Sided Body",
    captionDescription:
      "Versatile hauling solutions with secure side panels. Reliable delivery for your cargo needs.",
    imageUrl: "/assets/sidedbody.png",
  },
  {
    id: "2",
    captionTitle: "Low Bed",
    captionDescription:
      "Sturdy, stable transport for heavy machinery and oversized cargo. Optimal security for valuable equipment.",
    imageUrl: "/assets/lowbed.png",
  },
  {
    id: "3",
    captionTitle: "Package Truck",
    captionDescription:
      "Streamlined delivery services with customizable compartments. Hassle-free shipping for your packages.",
    imageUrl: "/assets/package.png",
  },
  {
    id: "4",
    captionTitle: "Container Truck",
    captionDescription:
      "Heavy-load transport made easy with spacious compartments. Secure, efficient logistics for large volumes.",
    imageUrl: "/assets/container.png",
  },
  {
    id: "5",
    captionTitle: "Flatbed Truck",
    captionDescription:
      "Versatile loading options for large, bulky items. Safe, efficient transportation with an open design.",
    imageUrl: "/assets/flatbed.png",
  },
  {
    id: "6",
    captionTitle: "Oil Tanker",
    captionDescription:
      "Spill-proof transportation for oil and petroleum products. Specialized fleet for secure oil delivery.",
    imageUrl: "/assets/oiltanker.png",
  },
  {
    id: "7",
    captionTitle: "Half-body Truck",
    captionDescription:
      "Efficient transportation for smaller cargo loads. Maneuverable handling for efficeient deliveries.",
    imageUrl: "/assets/halfbody.png",
  },
  {
    id: "8",
    captionTitle: "pick-up Truck",
    captionDescription:
      "Quick, convenient transportation for goods of all sizes. Reliable delivery with robust performance.",
    imageUrl: "/assets/pickup.png",
  },
  {
    id: "9",
    captionTitle: "Van",
    captionDescription:
      "Cost-effective solution for small to medium-sized deliveries. Versatile cargo space for efficient logistics.",
    imageUrl: "/assets/van.png",
  },
];


function Category() {
 

  return (
    <Container fluid className="figurebase" >
        <motion.div
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: -5, opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.5, timingFunction: easeOut }}
        viewport={{ once: true }}
      >

      <div>
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 900,
              },
              items: 3,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 650,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
            Semitab: {
              breakpoint: {
                max: 750,
                min: 650,
              },
              items: 2,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 750,
              },
              items: 3,
              partialVisibilityGutter: 30,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {Dataset.map((item) => (
            <div  style={{marginLeft:0.5+'rem',marginRight:0.5+'rem'}}>
            <figure className="figure-container">
              <Image
                width={100 + "%"}
                src={item.imageUrl}
                alt="Description of the image"
              />
              <figcaption className="caption">
                <h6 className="captionheader">{item.captionTitle}</h6>
                <p className="captiondescription">{item.captionDescription}</p>
              </figcaption>
            </figure>
            </div>
          ))}
        </Carousel>
      </div>
      </motion.div>
    </Container>
  );
}

export default Category;
