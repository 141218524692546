import axios from "axios";
  // const URL = "http://localhost:4002/api/v1/"
	const URL = "https://api.amabillstechnologies.com/api/v1/"

const header = {
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
}

class AuthService {

	postUserAuth(path, data) {
		return axios.post(URL + path, data, {
			headers: header
		}).then(result => {

			if (result && result.data.status === true) {

				if(result.data.data.type === "user"){
					return "Unauthorized access";
				}

				localStorage.setItem('user', JSON.stringify(result.data));
				localStorage.setItem('token', result.data.data.token);

				window.location.href = '/ld-admin/dashboard';
				return result.data;
			}

			return result.data

		}).catch(err => {
			console.log('error', err)

			
			if (err && err.code === 'ERR_NETWORK') {
				throw new Error(err.message + ', Kindly check your internet connection.');
			}

			if (err && err.code === 'ERR_BAD_REQUEST') {
				if (err.response.data && !(err.response.data instanceof Object)) {
					throw new Error('Invalid endpoint');
				}
				if (err.response.data.status === false && err.response.data.code === 422) {
					console.log('err 1', err.response.data.error)
					throw new Error(err.response.data.error);
				}

				if(err.response.data.message){
					throw new Error(err.response.data.message);
				}

				throw new Error(err.response.data.error);
			}
			
			throw new Error(err);
		});
	};



	forgotPassword(path, data) {
		return axios.patch(URL + path, data, {
			headers: header
		}).then(result => {

			return result.data

		}).catch(err => {

			
			if (err && err.code === 'ERR_NETWORK') {
				throw new Error(err.message + ', Kindly check your internet connection.');
			}

			if (err && err.code === 'ERR_BAD_REQUEST') {
				if (err.response.data && !(err.response.data instanceof Object)) {
					throw new Error('Invalid endpoint');
				}
				if (err.response.data.status === false && err.response.data.code === 422) {
					console.log('err 1', err.response.data.error)
					throw new Error(err.response.data.error);
				}

				if(err.response.data.message){
					throw new Error(err.response.data.message);
				}

				throw new Error(err.response.data.error);
			}
			
			throw new Error(err);
		});
	};


	logout() {
		localStorage.removeItem('user');
		localStorage.removeItem('token');
		return window.location.href = "/secret"
	}
}

const authService = new AuthService();
export default authService;