import React, { useState } from "react";
import "./dash.css";
import hamburger from "../assets/hamburger.svg";

import actionbtn from "../assets/actionbtn.svg";


import 'react-quill/dist/quill.snow.css';

import { Container, Row, Image, Table } from "react-bootstrap";

import DashboardSidebar from "./dashboardSidebar/sidebar";
import resourceService from "../services/resourses";
import { useEffect } from "react";













const Visitor = () => {
  const [toggled, setToggled] = useState(false);
  const [resources, setResources] = useState([]);

  useEffect(() => {
    const fetchResources = async () => {
      try {

        const response = await resourceService.listRecord("contacts/contacts");
        console.log('returned data', response)
        setResources(response.data);

      } catch (error) {
        console.log('outside error:: ', error);
      }
    };
    fetchResources();
  }, []);

  const renderResourceRows = () =>
    resources.map((resource, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{resource.contact_no}</td>
        <td>{resource.name}</td>
        <td>{resource.email}</td>
        <td>{resource.subject}</td>
        <td>{resource.message}</td>
        <td><Image src={actionbtn} /></td>
      </tr>
    ));

  return (
    <Container
      fluid
      style={{ display: "flex", width: 100 + "%" }}
      className="dashboardContainer"
    >
      <DashboardSidebar
        onBackdropClick={() => setToggled(false)}
        toggled={toggled}
      />

      <Container fluid className="bodycontainer">
        <div
          style={{
            display: "flex",
            gap: 1 + "rem",
            alignContent: "center",
            alignItems: "center",
          }}
          onClick={() => setToggled(!toggled)}
        >
          <Image src={hamburger} />
          <h5>Contact</h5>
        </div>
        <br />

        <Row>
          <Table striped responsive>
            <thead style={{ fontSize: 1 + "rem", fontFamily: "jost" }}>
              <tr>
                <th>#</th>
                <th>ContactID</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Subject</th>
                <th>Message</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: 0.90 + "rem", fontFamily: "jost" }}>
              {renderResourceRows()}
            </tbody>
          </Table>

        </Row>


      </Container>
    </Container>
  );
};

export default Visitor;





