import React, { useEffect, useRef } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import { Link } from "react-router-dom";
import logox1 from "../../assets/logox1.svg";
import logofoot from "../../assets/logofoot.svg";

import mark from "../../assets/mark.svg";

import "../waitlist/waitlist.css";
import TransporterForm from "./TransporterForm";

import whyTransporterData from "../WhyLoda/component/whyTransporterData.json";
import AnimatedWrapper from "../homepage/component/AnimatedWrapper";
import FormFooter from "../Formfooter/FormFooter";
import Services from '../../services/contactUs'

function TransporterWaitlist() {

  const monitoringInitiated = useRef(false);
  useEffect(() => {
    if (!monitoringInitiated.current) {
      Services.monitor();
      monitoringInitiated.current = true;
    }
  }, []);

  return (
    <Container fluid className="contactus">
      <Row>
      <Col xs={12} md={6} lg={5} className="order-md-2 formmain">
          <Link to="/">
            <Image className="navlogodisplay" src={logofoot} fluid alt="image" />
          </Link>
          
          <div>
          <strong style={{fontFamily:'jost',fontSize:1.5+'rem',color:'#F36F2E'}}>Transporter: Join The Waitlist</strong><br/>
          <em style={{fontSize:0.76+'rem',color:'#333232'}}>
          Join the waitlist and gain priority access to THE LODA's 
          innovative logistics platform. Fill out a few details in 
          the dropdown below to get started on optimizing your truck fleet.
          </em>
          </div>
        
          <TransporterForm />
         
        </Col>

        
        <Col xs={12} md={6} lg={7} className="order-md-1 formside">
          <Link to="/">
            <Image className="navlogo logoss" src={logox1} fluid alt="image" />
          </Link>
          
          <div>
            <h2 style={{color:"#F36F2E",marginBottom:1+"rem",marginTop:3+"rem"}}>
              <strong>Why Loda ?</strong>
            </h2>
          </div>
          <Row >
          {whyTransporterData.map((item) =>(

                    <Col xs={12} md={12} lg={6} style={{marginBottom:1+"rem"}} >
                       <AnimatedWrapper delay={item.num} >
                    <div style={{display:'flex',flexDirection:'row',gap:5,alignItems:"flex-start"}}>
                    <Image src={mark} />
                    <div>
                      <h5 style={{fontSize:1.1+"rem",fontFamily:"jost",color:"#FFFFFF"}}>
                        <strong>
                        {item.Header}
                        </strong>
                        </h5>
                      <p style={{fontSize:0.85+"rem",fontFamily:"jost",color:"#FFFBFF"}} >{item.Content}</p>
                    </div>
                  </div>
                  </AnimatedWrapper>
                  </Col>
                  ))}
                 
          </Row>

          <FormFooter />
        </Col>
       
      </Row>
    </Container>
  );
}

export default TransporterWaitlist;
