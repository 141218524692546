import React from "react";
// import { Row, Col } from "react-bootstrap";
import { Sidebar, MenuItem, Menu } from "react-pro-sidebar";
import {  Image } from "react-bootstrap";
import { Link } from "react-router-dom";

import logox1 from "../../../client/assets/logox1.svg";



import "../DashboardPage.js"

const DashboardSidebar = ({ onBackdropClick, toggled }) => {

  const menuItem = {
    height: 2.7 + "rem",
    fontSize: 0.87 + "rem",
  };

  return (
    <Sidebar
      onBackdropClick={onBackdropClick}
      toggled={toggled}
      breakPoint="always"
      
   
      className="sidebar"
    >
      
        
        <div style={{ marginTop: 1.5 + "rem", marginLeft: 1+ "rem"}}>
          <Image src={logox1} />
        </div>
   

      <Menu
        className="menu"
        style={{ marginBottom: 2 + "rem" }}
        menuItemStyles={{
          button: ({ active }) => ({
            color: active ? "#F36F2E" : "white",
            backgroundColor: active ? "#000000" : "transparent",
            "&:hover": {
              backgroundColor: active ? "#000000" : "black",
              color: active ? "#F36F2E" : "#F36F2E",
            },
          }),
        }}
      >
        <Link to="/ld-admin/dashboard">
          <MenuItem style={menuItem} >
            {" "}
            Dashboard
          </MenuItem>
        </Link>

        <Link to="/ld-admin/waitlist">
          <MenuItem style={menuItem} >
            {" "}
            Waitlist
          </MenuItem>
        </Link>

        <Link to="/ld-admin/blogs">
          <MenuItem style={menuItem} >
            {" "}
            Blog
          </MenuItem>
        </Link>

        <Link to="/ld-admin/contact">
          <MenuItem style={menuItem} >
            {" "}
            Contact
          </MenuItem>
        </Link>

        <Link to="/ld-admin/visitor">
          <MenuItem style={menuItem} >
            {" "}
            Visitors
          </MenuItem>
        </Link>


        <Link to="/ld-admin/career">
          <MenuItem style={menuItem} >
            {" "}
            Career
          </MenuItem>
        </Link>
      </Menu>

    </Sidebar>
  );
};

export default DashboardSidebar;
