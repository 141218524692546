import React, { useState } from "react";
import {
  Container, Image, Row, Col, Form, Dropdown, Modal, Button, Spinner, Offcanvas
} from "react-bootstrap";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, Link } from "react-router-dom";
import actionbtn from "../../assets/actionbtn.svg";
import notice from "../../assets/notice.svg";
import "../dashboard.css";
import { useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import resourceService from "../../services/resourses";


function News() {
  const [showModal, setShowModal] = useState(false);
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibilityState, setVisibilityState] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [deletionData, setDeletionData] = useState();
  const [editData, setEditData] = useState();
  const [showEditForm, setShow] = useState(false);
  const defaultImg = '/assets/yam.png';

  const handleClose = () => setShow(false);
  const handleShow = (value) => {
    setEditData(value);
    setShow(true)
  };


  useEffect(() => {
    const fetchResources = async () => {
      try {
        const response = await resourceService.listRecord("blogs/list");
        setResources(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log('outside error:: ', error);
        setLoading(false);
      }
    };
    fetchResources();
  }, []);


  useEffect(() => {
    if (resources.length > 0) {
      const onlineResources = resources.filter(item => item.status === 'online');
      const initialVisibilityState = Object.fromEntries(onlineResources.map(item => ([item.blog_id, true])));
      setVisibilityState(initialVisibilityState);
    }
  }, [resources]);



  const togglePost = async (blogId) => {
    const newStatus = visibilityState[blogId] ? 'offline' : 'online';
    const data = { blog_id: blogId, status: newStatus }
    try {
      const res = await resourceService.updateRecord('blogs/toggle', data);
      if (res) {
        if (res.status && res.status === true) {
          toast.success(res.message);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          console.log('response', res)
        } else {
          toast.error(res);
        }
      } else {
        toast.error("There was an error processing this request");
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }

    setVisibilityState((prevState) => ({
      ...prevState,
      [blogId]: !prevState[blogId]
    }));
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const navigate = useNavigate();

  const handleAction = (action, id) => {
    if (action === "view") {
      navigate(`/ld-admin/blog/${id}`);
    }

    if (action === "edit") {
      // console.log('editing item', id)
    }
    if (action === "delete") {
      setDeletionData(id)
      handleOpenModal(id);
    }
  };

  const deletePost = async () => {
    const data = { blog_id: deletionData }
    try {
      const res = await resourceService.deleteRecord('blogs/delete', data);
      if (res) {
        if (res.status && res.status === true) {
          toast.success(res.message);
          handleCloseModal()
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          toast.error(res);
        }
      } else {
        toast.error("There was an error processing this request");
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
        console.log('error', error)
      } else {
        toast.error("An error occurred. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  }

  const formatDate = (date) => {
    const createdAtDate = new Date(date);
    const options = { weekday: "short", day: "numeric", month: "long", year: "numeric" };
    const formattedDate = createdAtDate.toLocaleDateString(
      undefined, options
    );
    return formattedDate;
  }

  return (
    <Container fluid>

      <ToastContainer />
      <Row>
        {loading ? (
          <p>Loading...</p>
        ) : resources.length === 0 ? (
          <p>No Record found</p>
        ) : (
          resources.map((item) => (
            <Col xs={12} sm={12} md={4} lg={3}>
              <div>
                <figure>
                  <Link to={`/ld-admin/blog/${item.blog_id}`}>
                    {item.local_img ? (
                      <Image src={item.local_img} width="100%" height="200px" />
                    ) : (
                      <Image src={defaultImg} width="100%" height="200px" />
                    )}
                  </Link>

                  <figcaption
                    style={{
                      marginTop: -2.5 + "rem",
                      padding: 0.5 + "rem",
                      display: "flex",
                      justifyContent: "space-between",
                      color: "white",
                    }}
                  >
                    <Form.Check
                      type="switch"
                      id={`custom-switch-${item.blog_id}`}
                      label={item.status}
                      checked={!!visibilityState[item.blog_id]}
                      onChange={() => togglePost(item.blog_id)}
                    />

                    <Dropdown drop="down-centered">
                      <Dropdown.Toggle
                        variant="primary"
                        className="custom-toggle"
                        id="dropdown-basic"
                        style={{
                          border: "none",
                          background: "transparent",
                          padding: 0,
                        }}
                      >
                        <Image src={actionbtn} />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          style={{ fontSize: 0.85 + "rem" }}
                          onClick={() => handleAction("view", item.blog_id)}
                        >
                          View
                        </Dropdown.Item>
                        <Dropdown.Item
                          style={{ fontSize: 0.85 + "rem" }}
                          onClick={() => handleShow(item)}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          style={{ fontSize: 0.85 + "rem" }}
                          onClick={() => handleAction("delete", item.blog_id)}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </figcaption>
                </figure>

                <div>
                  <h6>
                    <strong>{item.title}</strong>
                  </h6>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: "space-between",
                      color: "#616161",
                      fontSize: 0.8 + "rem",
                      fontWeight: "bold",
                    }}
                  >
                    <em>{formatDate(item.createdAt)}</em>
                    <em>Views: {item.views}</em><br />
                  </div>
                </div>
              </div>
              <br />
              <br />
            </Col>
          ))
        )}

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: 'jost'
              }}
            >
              <Image src={notice} />
              <p style={{ fontSize: 0.9 + "rem", textAlign: 'center' }}>
                Are you sure you want to delete this post?<br />
                This action cannot be reversed!
              </p>
              <div style={{ display: "flex", gap: 6, }}>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={handleCloseModal}
                >
                  <span style={{ marginLeft: 0.4 + 'rem', marginRight: 0.4 + 'rem' }}>No</span>
                </Button>


                <Button
                  onClick={deletePost}
                  variant="primary"
                  style={{
                    backgroundColor: "#F36F2E",
                    borderColor: "#313434",
                    color: "#FFFFF",
                    textAlign: "center",
                  }}
                  size="md"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span
                        style={{
                          fontSize: 0.8 + "rem",
                          marginLeft: 0.3 + "rem",
                          marginRight: 0.3 + "rem",
                        }}
                      >
                        Deleting...
                      </span>
                    </>
                  ) : (
                    <span
                      style={{
                        fontSize: 0.8 + "rem",
                        marginLeft: 0.3 + "rem",
                        marginRight: 0.3 + "rem",
                      }}
                    >
                      YES
                    </span>
                  )}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Row>

      <Offcanvas show={showEditForm} className="offcanvas" placement='end' backdrop="static" onHide={handleClose} >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontFamily: "jost", fontSize: 1.2 + "rem" }}><strong>Update Blog</strong></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <BlogEditform editData={editData} />
        </Offcanvas.Body>
      </Offcanvas>
    </Container>
  );
}


const BlogEditform = ({ editData }) => {

  const quillModules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link', 'image'],
      ['clean']
    ],
  }

  const quillFormats = [
    'header', 'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'link', 'image'
  ]

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    platform: editData.platform,
    title: editData.title || "",
    body: editData.body || "",
    author: editData.author || "",
    blog_id: editData.blog_id,
    file: null,
  });

  const handleOnchange = (event, name) => {
    if (name === 'body') {
      setForm(prevState => ({
        ...prevState,
        body: event
      }));
    } else {
      const { value } = event.target;
      setForm(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setForm(prevState => ({
      ...prevState,
      [name]: files[0]
    }));
  };

  const createBlogPost = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await resourceService.updateRecordWithImage('blogs/update', form);
      if (res) {
        if (res.status && res.status === true) {
          toast.success(res.message);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          toast.error(res);
        }
      } else {

        toast.error("There was an error processing this request");
      }
    } catch (error) {

      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <Form  >
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="formlabel">Title</Form.Label>
        <Form.Control
          required
          onChange={(value) => handleOnchange(value, 'title')}
          className="formcontrol"
          type="text"
          value={form.title}
          name="title"
          placeholder="Enter blog title"
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="formlabel">Body</Form.Label>
        <ReactQuill
          placeholder="Enter blog content"
          className="formcontrol"
          name="body"
          value={form.body}
          onChange={(value) => handleOnchange(value, 'body')}
          modules={quillModules}
          formats={quillFormats}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="formlabel">Author</Form.Label>
        <Form.Control
          required
          onChange={(value) => handleOnchange(value, 'author')}
          className="formcontrol"
          type="text"
          value={form.author}
          name="author"
          placeholder="Enter blog author"
        />
      </Form.Group>

      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label className="formlabel" >Banner Image</Form.Label>
        <Form.Control
          className="formcontrol"
          type="file"
          name="file"
          onChange={handleFileChange}
        />
      </Form.Group><br />
      <br />

      <Button
        onClick={createBlogPost}
        variant="primary"
        style={{
          backgroundColor: "#F36F2E",
          borderColor: "#313434",
          color: "#FFFFF",
          borderRadius: 36 + "px",
          textAlign: "center",
        }}
        size="md"
        disabled={isLoading}
      >
        {isLoading ? (
          <>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span
              style={{
                fontSize: 0.8 + "rem",
                marginLeft: 0.3 + "rem",
                marginRight: 0.3 + "rem",
              }}
            >
              Loading...
            </span>
          </>
        ) : (
          <span
            style={{
              fontSize: 0.8 + "rem",
              marginLeft: 0.3 + "rem",
              marginRight: 0.3 + "rem",
            }}
          >
            Update Blog
          </span>
        )}
      </Button>
    </Form>
  );
};

export default News;
