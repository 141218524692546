import React from 'react';
import { Container, Accordion } from 'react-bootstrap';
import { easeOut, motion } from "framer-motion";
import "../homepage/homepage.css";

const QuestionData = [
  {
    id: "1",
    num:"0.2",
    Question: "What services does The Loda offer?",
    Answer: "The Loda provides a variety of transportation solutions, including car ride-hailing, tricycle hailing, truck hailing for goods transportation, and delivery services for individuals, restaurants, supermarkets, and pharmacies. Whether you need a quick ride or heavy-duty transportation, The Loda has you covered.",
  },
  {
    id: "2",
    num:"0.4",
    Question: " How do I book a ride or transport goods using The Loda? ",
    Answer: "Booking with The Loda is simple! Just download the app, sign up, and choose the service you need — whether it’s a ride, tricycle, or truck. Enter your destination or delivery details, and our network of drivers will be at your service.",
  },
  {
    id: "3",
    num:"0.6",
    Question: " How can I track my ride or delivery?",
    Answer: "Once your ride or shipment is confirmed, you can track it in real-time through our app. You’ll see the driver's location and receive updates on the estimated time of arrival.",
  },
  {
    id: "4",
    num:"0.8",
    Question: "What areas does The Loda currently operate in?",
    Answer: "The Loda is currently set to be available in cities across Nigeria. As we expand, we will also offer services in other African countries, including Kenya, Ghana, South Africa, Morocco, Egypt, and Uganda.",
  },
  {
    id: "5",
    num:"0.9",
    Question: "How are prices determined for rides and goods transportation?",
    Answer: "Prices are determined based on factors such as distance, type of service (e.g., car, tricycle, or truck), and demand. You’ll always see the estimated cost upfront before confirming your booking.",
  },
  {
    id: "6",
    num:"0.95",
    Question: "What payment methods does The Loda accept?",
    Answer: "We accept multiple payment methods, including Cash, credit/debit cards, wallets, Bank Transfers, and other secure digital payment platforms. You can also load your Loda wallet to make payments faster.",
  },
  {
    id: "7",
    num:"1.1",
    Question: "Can I schedule a ride or goods transport in advance?",
    Answer: "Yes! With The Loda, you can schedule your rides or shipments in advance for a convenient and worry-free experience. Just select your desired date and time when booking.",
  },
  {
    id: "8",
    num:"1.3",
    Question: "What safety measures are in place for riders and drivers?",
    Answer: "At The Loda, your safety is our priority. All drivers undergo rigorous background checks and vehicle inspections. Our platform also provides in-app emergency features, live location sharing, and a 24/7 support team to assist you whenever needed.",
  },
  {
    id: "9",
    num:"1.5",
    Question: "How do I become a driver or transporter for The Loda?",
    Answer: "Interested in joining The Loda as a driver or transporter? You can apply directly through our app or website. Once your application is reviewed and your vehicle passes inspection, you’ll be onboarded and ready to start earning.",
  },
  {
    id: "10",
    num:"1.7",
    Question: " What do I do if I experience an issue with my ride or delivery?",
    Answer: "If you face any issues during your ride or delivery, you can contact our customer support team through the app. We’ll work quickly to resolve the matter and ensure your experience remains smooth.",
  },
  {
    id: "11",
    num:"1.9",
    Question: "Does The Loda offer insurance for goods transported?",
    Answer: "Yes, we offer insurance coverage for goods transported through our truck hailing service, providing peace of mind for both businesses and individuals.",
  },
  {
    id: "12",
    num:"2.1",
    Question: "Are there any loyalty or rewards programs for frequent users?",
    Answer: "We value our loyal customers! The Loda offers a rewards program where you can earn points for every ride or delivery booked, which can be redeemed for discounts or special offers.",
  },
  {
    id: "13",
    num:"2.3",
    Question: "Can I cancel a ride or delivery?",
    Answer: "Yes, you can cancel a ride or delivery before it begins. Please note that cancellation fees may apply if the cancellation is made after a certain time frame.",
  },
  {
    id: "14",
    num:"2.5",
    Question: "How does The Loda handle refunds?",
    Answer: "Refunds are processed if there’s an issue that qualifies under our refund policy. Contact our customer support team with your request, and we’ll assist you with the process.",
  },
  {
    id: "15",
    num:"2.7",
    Question: "Can Individual and businesses register on The Loda App?",
    Answer: "Absolutely! Both individuals and businesses can sign up on The Loda App to access our diverse transport services.",
  },
];



function Question() {
  return (
  
    <Container fluid id="hero" className="FAQ" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center" }}>
      
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: -5, opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.4, timingFunction: easeOut }}
        viewport={{ once: true }}
      >
       
  
      <h2 style={{fontFamily:'jost',fontSize:2.2+'rem',color:'#515151',textAlign:'center'}} ><strong>Frequently Asked Questions</strong></h2>
      <p style={{textAlign:'center', fontFamily:'jost',fontSize:0.85+'rem'}}>Below are list of frequently asked questions, tap on a question to view the answer.</p>
      </motion.div>
      <br />

      <Accordion className="mainaccordion" defaultActiveKey="0" flush>
        {QuestionData.map((item) => (
           <motion.div
           initial={{ y: 20, opacity: 0 }}
           whileInView={{ y: -5, opacity: 1 }}
           transition={{ duration: 0.3, delay: item.num, timingFunction: easeOut }}
           viewport={{ once: true }}
         >
          <Accordion.Item key={item.id} eventKey={item.id}>
            <Accordion.Header>{item.Question}</Accordion.Header>
            <Accordion.Body style={{ justifyContent: 'left' }}>
              {item.Answer}
            </Accordion.Body>
          </Accordion.Item>
          </motion.div>
        ))}
      </Accordion>

    </Container>
  )
}

export default Question;
