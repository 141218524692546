import React, { useState, useEffect } from "react";
import "./dash.css";
import hamburger from "../assets/hamburger.svg";

import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

import DashboardSidebar from "./dashboardSidebar/sidebar";
import MyDatePicker from "./components/MyDatePicker";
import Services from '../services/resourses';

const DashBoardPage = () => {

  const [resources, setResources] = useState([]);
  const [dashboard, setDashboard] = useState([]);

  useEffect(() => {
    const fetchResources = async () => {
      try {

        const response = await Services.listRecord("dashboard");
        console.log('response', response)
        setResources(response.data.data);
        setDashboard(response.data)
        console.log('data', resources)
        console.log('dashboard', dashboard);

      } catch (error) {
        console.log('outside error:: ', error);
      }
    };
    fetchResources();
  },[]);

  const containerstyle = {
    padding: 1.2 + "rem",

    borderColor: "#CACACA",
    borderWidth: 0.08 + "rem",
    borderStyle: "solid",
    borderRadius: 0.5 + "rem",
    boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.1)",
    marginBottom: 1.8 + "rem",
  };

  const [toggled, setToggled] = useState(false);

  return (
    <Container
      fluid
      style={{ display: "flex", width: 100 + "%" }}
      className="dashboardContainer"
    >
      <DashboardSidebar
        onBackdropClick={() => setToggled(false)}
        toggled={toggled}
      />

      <Container fluid className="bodycontainer">
        <div
          style={{
            display: "flex",
            gap: 1 + "rem",
            alignContent: "center",
            alignItems: "center",
          }}
          onClick={() => setToggled(!toggled)}
        >
          <Image src={hamburger} />
          <h6>Dashboard</h6>
        </div>
        <br />

        <div style={{ display: 'flex', justifyContent: "space-between" }}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: 0.2 }}
          >
            <span
              style={{
                fontSize: 0.82 + "rem",
                fontFamily: "jost",
                color: "grey",
              }}
            >
              {/* Total Visitor */}
            </span>
            <h5
              style={{
                fontSize: 2.1 + "rem",
                fontFamily: "jost",
                color: "#000000",
              }}
            >
              {/* <strong>{dashboard.visitors}</strong> */}
            </h5>
          </div>
          <MyDatePicker />
        </div>






        {/* <Row>
          {resources.map((item) => (
            <Col
              sm={12}
              md={6}
              lg={3}
              style={{ marginBottom: 0 + "rem" }}
              key={item.id}
            >
              <div className="box" style={containerstyle}>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 0.2 }}
                >
                  <span
                    style={{
                      fontSize: 0.82 + "rem",
                      fontFamily: "jost",
                      color: "grey",
                    }}
                  >
                    {item.title}
                  </span>
                  <h5
                    style={{
                      fontSize: 1.1 + "rem",
                      fontFamily: "jost",
                      color: "#000000",
                    }}
                  >
                    <strong>{item.no}</strong>
                  </h5>
                </div>
              </div>
            </Col>
          ))}
        </Row> */}



<Row>
      {resources.map((item, index) => (
        <Col
          sm={12}
          md={6}
          lg={3}
          style={{ marginBottom: 0 + "rem" }}
          key={item.id}
        >
          <div className="box" style={containerstyle}>
            <div
              style={{ display: "flex", flexDirection: "column", gap: 0.2 }}
            >
              {index === 0 ? (
                <Link to={`/ld-admin/analytics`}>
                  <span
                    style={{
                      fontSize: 0.82 + "rem",
                      fontFamily: "jost",
                      color: "grey",
                    }}
                  >
                    {item.title}
                  </span>
                </Link>
              ) : (
                <span
                  style={{
                    fontSize: 0.82 + "rem",
                    fontFamily: "jost",
                    color: "grey",
                  }}
                >
                  {item.title}
                </span>
              )}
              <h5
                style={{
                  fontSize: 1.1 + "rem",
                  fontFamily: "jost",
                  color: "#000000",
                }}
              >
                <strong>{item.no}</strong>
              </h5>
            </div>
          </div>
        </Col>
      ))}
    </Row>


      </Container>
    </Container>
  );
};

export default DashBoardPage;
