/** CORE LIB */
import React, { useState, useEffect } from "react";
import "./dash.css";
import hamburger from "../assets/hamburger.svg";
import {
  Container, Row, Image, Table, Tab, Tabs, Form, Button, Modal, Spinner
} from "react-bootstrap";

import MyDatePicker from "./components/MyDatePicker";

// import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DashboardSidebar from "./dashboardSidebar/sidebar";
// import actionbtn from "../assets/actionbtn.svg";
import resourceService from "../services/resourses";
import Select from "react-select";
import makeAnimated from 'react-select/animated'
const animatedComponents = makeAnimated();

const WaitList = () => {
  const [resources, setResources] = useState([]);
  const [toggled, setToggled] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [uniqueStates, setUniqueStates] = useState([]);

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const response = await resourceService.listRecord(
          "contacts/waiting-list"
        );
        console.log("returned data", response);
        setResources(response.data);
        const states = response.data.map((resource) => resource.state);
        const uniqueStates = [...new Set(states)];
        setUniqueStates(uniqueStates);
      } catch (error) {
        console.log("outside error:: ", error);
      }
    };
    fetchResources();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };

  const filteredResources = resources.filter((resource) => {
    return (
      (selectedState === "" || resource.state === selectedState) &&
      resource.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const renderResourceRows = (waitlistType) =>
    filteredResources
      .filter((resource) => resource.waitlist_type === waitlistType)
      .map((resource, index) => {
        const createdAtDate = new Date(resource.createdAt);
        const options = {
          weekday: "short",
          day: "numeric",
          month: "long",
          year: "numeric",
        };
        const formattedDate = createdAtDate.toLocaleDateString(
          undefined,
          options
        );

        return (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{resource.name}</td>
            <td>{resource.company_name}</td>
            <td>{resource.email}</td>
            <td>{resource.phone}</td>
            <td>{resource.state}</td>
            <td>{formattedDate}</td>
            <td>{resource.status}</td>
            <td>{resource.account_type}</td>
            <td>{resource.waitlist_type}</td>
            {waitlistType === "transporter" && <td>{resource.fleet_size}</td>}
           
          </tr>
        );
      });

  const [showMsgShipperModal, setShow] = useState(false);
  const [showMsgTransporterModel, showModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const openTransporterModal = () => showModal(true);
  const closeTransporterModal = () => showModal(false);


  const [isLoading, setIsLoading] = useState(false);


  const [selectedUsers, setSelectedUsers] = useState([]);
  const [form, setForm] = useState(
    { subject: "", message: "", users: [] }
  )

  const handleOnchange = (event) => {
    const { value, name } = event.target;
    setForm(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleSelectedUsers = (selectedUsers) => {
    setSelectedUsers(selectedUsers)
    setForm(prevState => ({
      ...prevState,
      users: selectedUsers
    }));
  };

  const ClearFormAndCloseModal = async () => {
    setForm({ subject: '', message: '', users: [] });
    setSelectedUsers([]);
    closeTransporterModal();
    setShow(false);
  };


  const submitShipperForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await resourceService.addRecord('contacts/contact-waitlist', form);
      if (res) {
        if (res.status && res.status === true) {
          toast.success(res.message);
          ClearFormAndCloseModal();
        } else {
          toast.error(res);
        }
      } else {

        toast.error("There was an error processing this request");
      }
    } catch (error) {

      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };




  return (
    <Container
      fluid
      style={{ display: "flex", width: 100 + "%" }}
      className="dashboardContainer"
    >
      <DashboardSidebar
        onBackdropClick={() => setToggled(false)}
        toggled={toggled}
      />

      <Container fluid className="bodycontainer">
        <div
          style={{
            display: "flex",
            gap: 1 + "rem",
            alignContent: "center",
            alignItems: "center",
          }}
          onClick={() => setToggled(!toggled)}
        >
          <Image src={hamburger} />
          <h5>Waitlist</h5>
        </div>
        <br />

        <Row>
          <Tabs
            defaultActiveKey="Shipper"
            id="justify-tab-example"
            className="mb-3 selectusertype"
            justify
          >
            <Tab
              style={{ marginTop: 0.5 + "rem" }}
              eventKey="Shipper"
              title="Shipper"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "60%",
                    marginBottom: 1 + "rem",
                    gap: 3,
                  }}
                >
                  <Form.Control
                    style={{ width: 100 + "%" }}
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />

                  <Form.Select
                    value={selectedState}
                    onChange={handleStateChange}
                  >
                    <option value="">All States</option>
                    {uniqueStates.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </Form.Select>
                  
                 <MyDatePicker  />
                 
                </div>

                <div>
                  <Button
                    onClick={handleShow}
                    variant="primary"
                    style={{
                      backgroundColor: "#F36F2E",
                      borderColor: "#313434",
                      color: "#FFFFF",
                      borderRadius: 36 + "px",
                      textAlign: "center",
                      width: "100%",
                    }}
                    size="md"
                  >
                    <span
                      style={{
                        fontSize: 0.9 + "rem",
                        marginLeft: 0.5 + "rem",
                        marginRight: 0.5 + "rem",
                      }}
                    >
                      Message Shippers
                    </span>
                  </Button>
                </div>
              </div>

              <Table striped responsive style={{ overflow: "hidden" }}>
                <thead style={{ fontSize: 1 + "rem", fontFamily: "jost" }}>
                  <tr>
                    <th>#</th>
                    <th>Full Name</th>
                    <th>Company Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>State</th>
                    <th>Joined On</th>
                    <th>Status</th>
                    <th>Account Type</th>
                    <th>Waitlist Type</th>
                   
                  </tr>
                </thead>
                <tbody style={{ fontSize: 0.95 + "rem", fontFamily: "jost" }}>
                  {renderResourceRows("shipper")}
                </tbody>
              </Table>

              <ToastContainer />
              <Modal show={showMsgShipperModal} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title
                    style={{ fontFamily: "jost", fontSize: 1.2 + "rem" }}>
                    Message Shippers
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={filteredResources
                        .filter((resource) => resource.waitlist_type === "shipper")
                        .map((resource) => ({
                          contact_id: resource.contact_id,
                          email: resource.email,
                          name: resource.name,
                        }))
                      }
                      getOptionValue={(option) => option.email}
                      getOptionLabel={(option) => option.email}
                      required
                      onChange={handleSelectedUsers}
                      value={selectedUsers}
                    />

                    <br />

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label style={{ fontFamily: "jost", fontSize: 0.87 + "rem" }}>
                        Subject
                      </Form.Label>
                      <Form.Control
                        required
                        onChange={handleOnchange}
                        name="subject"
                        type="text"
                        placeholder="Enter email subject" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                      <Form.Label style={{ fontFamily: "jost", fontSize: 0.87 + "rem" }}>
                        Email Body
                      </Form.Label>
                      <Form.Control
                        required
                        onChange={handleOnchange}
                        name="message"
                        as="textarea"
                        placeholder="Compose your message"
                        rows={3} />
                    </Form.Group>
                    <Button
                      onClick={submitShipperForm}
                      variant="primary"
                      style={{
                        backgroundColor: "#F36F2E",
                        borderColor: "#313434",
                        color: "#FFFFF",
                        borderRadius: 36 + "px",
                        textAlign: "center",
                      }}
                      size="md"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span
                            style={{
                              fontSize: 0.8 + "rem",
                              marginLeft: 0.3 + "rem",
                              marginRight: 0.3 + "rem",
                            }}
                          >
                            Loading...
                          </span>
                        </>
                      ) : (
                        <span
                          style={{
                            fontSize: 0.8 + "rem",
                            marginLeft: 0.3 + "rem",
                            marginRight: 0.3 + "rem",
                          }}
                        >
                          SEND MESSAGE
                        </span>
                      )}
                    </Button>
                  </Form>
                </Modal.Body>
              </Modal>
            </Tab>



            {/* TRANSPORTER TAB */}
            <Tab
              style={{ marginTop: 0.3 + "rem" }}
              eventKey="Transporter"
              title="Transporter">
              <div style={{
                display: "flex", flexDirection: "row", justifyContent: "space-between",
              }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "60%",
                    marginBottom: 1 + "rem",
                    gap: 3,
                  }}>
                  <Form.Control
                    style={{ width: 100 + "%" }}
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <Form.Select
                    value={selectedState}
                    onChange={handleStateChange}
                  >
                    <option value="">All States</option>
                    {uniqueStates.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </Form.Select>

                  <MyDatePicker />
                </div>

                <div>
                  <Button
                    onClick={openTransporterModal}
                    variant="primary"
                    style={{
                      backgroundColor: "#F36F2E",
                      borderColor: "#313434",
                      color: "#FFFFF",
                      borderRadius: 36 + "px",
                      textAlign: "center",
                      width: "100%",
                    }}
                    size="md"
                  >
                    <span
                      style={{
                        fontSize: 0.9 + "rem",
                        marginLeft: 0.5 + "rem",
                        marginRight: 0.5 + "rem",
                      }}
                    >
                      Message Transporters
                    </span>
                  </Button>
                </div>

               
              </div>

              <Table striped responsive>
                <thead style={{ fontSize: 1 + "rem", fontFamily: "jost" }}>
                  <tr>
                    <th>#</th>
                    <th>Full Name</th>
                    <th>Company Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>State</th>
                    <th>Joined On</th>
                    <th>Status</th>
                    <th>Account Type</th>
                    <th>Waitlist Type</th>
                    <th>Fleet Size</th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: 0.95 + "rem", fontFamily: "jost" }}>
                  {renderResourceRows("transporter")}
                </tbody>
              </Table>

              <ToastContainer />
              <Modal
                show={showMsgTransporterModel}
                onHide={closeTransporterModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title
                    style={{ fontFamily: "jost", fontSize: 1.2 + "rem" }}
                  >
                    Message Transporters
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={filteredResources
                        .filter((resource) => resource.waitlist_type === "transporter")
                        .map((resource) => ({
                          contact_id: resource.contact_id,
                          email: resource.email,
                          name: resource.name,
                        }))
                      }
                      getOptionValue={(option) => option.email}
                      getOptionLabel={(option) => option.email}
                      required
                      onChange={handleSelectedUsers}
                      value={selectedUsers}
                    />
                    <br />


                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label
                        style={{ fontFamily: "jost", fontSize: 0.87 + "rem" }}
                      >
                        Subject
                      </Form.Label>
                      <Form.Control
                        required
                        onChange={handleOnchange}
                        name="subject"
                        type="text"
                        placeholder="Enter email subject"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label
                        style={{ fontFamily: "jost", fontSize: 0.87 + "rem" }}
                      >
                        Email Body
                      </Form.Label>
                      <Form.Control
                        required
                        onChange={handleOnchange}
                        name="message"
                        as="textarea"
                        placeholder="Compose your message"
                        rows={5}
                      />
                    </Form.Group>

                    <Button
                    onClick={submitShipperForm}
                    variant="primary"
                    style={{
                      backgroundColor: "#F36F2E",
                      borderColor: "#313434",
                      color: "#FFFFF",
                      borderRadius: 36 + "px",
                      textAlign: "center",
                    }}
                    size="md"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span
                          style={{
                            fontSize: 0.8 + "rem",
                            marginLeft: 0.3 + "rem",
                            marginRight: 0.3 + "rem",
                          }}
                        >
                          Loading...
                        </span>
                      </>
                    ) : (
                      <span
                        style={{
                          fontSize: 0.8 + "rem",
                          marginLeft: 0.3 + "rem",
                          marginRight: 0.3 + "rem",
                        }}
                      >
                        SEND MESSAGE
                      </span>
                    )}
                  </Button>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  
                </Modal.Footer>
              </Modal>
            </Tab>
          </Tabs>
        </Row>
      </Container>
    </Container>
  );
};

export default WaitList;
