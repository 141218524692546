import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { easeOut, motion } from "framer-motion";

import "../../homepage/homepage.css";

const Dataset = [
  {
    id: "1",
    num:"0.2",
    header: "Instant Request",
    paragraph:
      "Order a ride or delivery truck in a matter of seconds. Experience quick logistics solutions for urgent transportation needs.",
    classname:"InstantRequest"
  },
  {
    id: "2",
    num:"0.4",
    header: "Book for later request",
    paragraph:
      "Book a ride or delivery truck for specific date and time ahead while we ensure optimal coordination and efficiency with drivers.",
    classname:"ScheduleRequest"
  },

  {
    id: "3",
    num:"0.6",
    header: "Extensive Transporter Network:",
    paragraph:
      "Gain access to a broad range of vetted drivers and transporters , providing a wide array of services tailored to meet diverse logistics needs.",
    classname:"Transporter"
  },

  {
    id: "4",
    num:"0.8",
    header: "Wallet Feature",
    paragraph:
      "Simplify financial transactions and manage logistics expenses seamlessly with our integrated digital wallet system.",
    classname:"Wallet"
  },
  {
    id: "5",
    num:"0.85",
    header: "Real-Time Insights:",
    paragraph:
      "Effortlessly monitor your Ride, shipment or vehicle with our advanced real-time tracking and monitoring system, keeping your informed at all times.",
    classname:"Tracking"
  },
  
  {
    id: "6",
    num:"0.9",
    header: "Record Analysis",
    paragraph:
      "Provides riders with trip logs and expense summaries,  Drivers/ transporters with income summaries of their vehicles , while shippers can review past logistics requests and track their shipping history.",
     classname:"BuyAirtime"
  },
 
];

function Features() {
  const cardbody = {
    backgroundColor:'#F5F6F7',
    borderRadius:11.96+'px',
    padding:1.1+'rem',
    marginBottom:1.5+'rem',
  }
  return (
    <Container fluid className="featurescontainer">
          <div
        style={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1 className="categoryheader">
          <strong>Features</strong>
        </h1>
       
      </div>
      <br/>
      <Row>
        {Dataset.map((item) => (
          <Col xs={12} sm={12} md={6} lg={4}>
             <motion.div
           initial={{ y: 20, opacity: 0 }}
           whileInView={{ y: -5, opacity: 1 }}
           transition={{ duration: 0.3, delay: item.num, timingFunction: easeOut }}
           viewport={{ once: true }}
         >
            <div style={cardbody} className={item.classname}>
              <h4 className="featureheader">{item.header}</h4>
              <p className="featuredescription">{item.paragraph}</p>
            </div>
            </motion.div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Features;
