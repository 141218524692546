import React from "react";
import { Container, Row, Image } from "react-bootstrap";
import { easeOut, motion } from "framer-motion";
import "../../homepage/homepage.css";

import openrole from "../../../assets/openrole.svg";



function OpenRole() {
  return (
    <Container fluid className="introsection">
        <h1 style={{color:'#F36F2E'}}><strong>Open Roles</strong></h1><br/>
        <motion.div
           initial={{ y: 20, opacity: 0 }}
           whileInView={{ y: -5, opacity: 1 }}
           transition={{ duration: 0.3, delay:0.5, timingFunction: easeOut }}
           viewport={{ once: true }}
         >
  <Container className="introinnersection text-center">
    <Row>
     <center>
        <Image src={openrole} />
       <h5>No Open Role</h5>
       <p style={{color:'#333232',fontSize:0.85+'rem'}}>Currently their is no open role, you can check later</p>


     </center>
    </Row>
  </Container>
  </motion.div>
</Container>

  );
}

export default OpenRole;
