import React, { useEffect, useRef } from 'react';
import HeroSection from './component/HeroSection';
import StickyNavbar from '../reuse-component/Navbar/StickyNavbar';


import DownloadApp from '../homepage/component/DownloadApp';
import Contactus from '../homepage/component/Contactus';
import FooterSection from '../homepage/component/FooterSection';
import OtherSection from './component/OtherSection';
import Services from '../../services/contactUs'


function WhyLoda() {
  
  const monitoringInitiated = useRef(false);
  useEffect(() => {
    if (!monitoringInitiated.current) {
      Services.monitor();
      monitoringInitiated.current = true;
    }
  }, []);

  return (
    <>
        <StickyNavbar />
        <HeroSection />
       <OtherSection />
        
        <DownloadApp />
        <Contactus />
        <FooterSection />
      
    </>
  )
}

export default WhyLoda
